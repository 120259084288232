<template>
  <div v-show="isShowDetail" class="noselect mx-7 my-5 overflow-hidden">
    <v-btn
      class="mr-2"
      icon
      size="24"
      slim
      variant="plain"
      @click="onClickBack"
    >
      <v-icon class="mt-n1" color="rgba(255, 255, 255, 1)">
        mdi-arrow-left-bold-circle
      </v-icon>
    </v-btn>
    <span class="text-white cl-font-24-bold">
      {{ eventDetail?.event_info?.event_name }}
    </span>
    <v-divider class="mt-5 mb-5" :thickness="1" color="#613BBB"></v-divider>
    <div class="py-5 overflow-auto" :style="{ height: getHeightMission() }">
      <div class="flex text-white cl-font-16-normal">
        <div class="cl-font-16-bold mr-6">
          {{ eventDetail?.event_info?.event_type_name }}
        </div>
        {{ $t("mission.start_time") }}:
        {{
          getTimeEvent(
            eventDetail?.event_info?.start_time,
            eventDetail?.event_info?.end_time
          )
        }}
      </div>
      <div class="flex justify-between">
        <div class="flex-1 text-customPurple cl-font-16-bold">
          {{ eventDetail?.event_info?.description }}
          <div
            v-if="checkShowEventFinal()"
            class="flex justify-between items-center my-10"
          >
            <div class="flex-1 mr-7">
              <div class="text-customYellow2 cl-font-16-bold">
                {{ $t("mission.reward_event_final_title") }}
              </div>
              <div class="text-customGray cl-font-14-normal">
                {{ $t("mission.reward_event_final_des") }}
              </div>
              <div class="flex items-center mt-1">
                <v-progress-linear
                  :model-value="eventDetail?.complete_mission"
                  buffer-opacity="1"
                  :buffer-value="eventDetail?.total_mission"
                  height="12"
                  :max="eventDetail?.total_mission"
                  min="0"
                  rounded
                  :location="false"
                ></v-progress-linear>
                <div
                  class="ml-2 mt-1 cl-font-14-bold"
                  :class="
                    getClassTarget(
                      eventDetail?.complete_mission,
                      eventDetail?.total_mission
                    )
                  "
                >
                  {{ eventDetail?.complete_mission }}/{{
                    eventDetail?.total_mission
                  }}
                </div>
              </div>
            </div>
            <div class="w-32">
              <div
                v-if="eventDetail?.event_info?.reward?.items?.item_id"
                class="d-flex flex-column gap-2"
              >
                <v-img
                  :src="eventDetail?.event_info?.reward?.items.image_url"
                ></v-img>
                <div
                  v-if="checkShowQuantity()"
                  class="quantity-item cl-font-12-normal"
                >
                  Số lượng:
                  {{ eventDetail?.event_info?.reward?.items.quantity }}
                </div>
              </div>
              <div
                v-else-if="eventDetail?.event_info?.reward?.g_point > 0"
                class="d-flex flex-column gap-2"
              >
                <img src="@/assets/images/icons/g_point.svg" alt="" />
                <div class="quantity-item cl-font-12-bold text-white">
                  {{ eventDetail?.event_info?.reward?.g_point }}
                  <span class="text-customYellow2">GP</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-64 min-w-64 ml-12">
          <v-img :src="eventDetail?.event_info?.event_image"></v-img>
        </div>
      </div>
      <v-btn
        variant="tonal"
        class="w-65 h-9.5 cl-font-14-bold rounded flex justify-center items-center text-white"
        :readonly="eventDetail?.event_state === state.ExpireTime"
        :class="getBgColorEvent(eventDetail?.event_state)"
        @click="handleActionEvent(eventDetail?.event_state)"
      >
        {{ getTextEvent(eventDetail?.event_state) }}
      </v-btn>
      <div class="flex mt-7">
        <img src="@/assets/images/icons/focus.svg" alt="" srcset="" />
        <span class="ml-2 text-white cl-font-20-bold">{{
          $t("mission.challenging_missions")
        }}</span>
      </div>
      <div
        v-for="(item, index) in eventDetail.mission_info"
        :key="index"
        class="mt-5"
      >
        <div class="flex">
          <v-avatar class="mr-3" rounded="0" size="64">
            <v-img :src="item.mission_image"> </v-img>
          </v-avatar>
          <div class="flex flex-col w-full">
            <div class="mb-1 flex text-white items-center cl-font-16-bold">
              <div
                v-show="item?.total_point_receive"
                class="px-2 mr-3 border-solid rounded border-2 border-customYellow2 cl-font-12-bold text-customYellow2"
              >
                +{{ item?.total_point_receive }} GPoint
              </div>
              <div
                class="cursor-pointer"
                @click.stop="onRedirectLink(item?.link)"
              >
                {{ item?.mission_name }}
              </div>
            </div>
            <div class="text-customGray cl-font-14-normal">
              {{ item?.description }}
            </div>
            <div class="flex items-center">
              <v-progress-linear
                :model-value="item?.present"
                buffer-opacity="1"
                :buffer-value="item?.target"
                height="12"
                :max="item?.target"
                min="0"
                rounded
                :location="false"
              ></v-progress-linear>
              <div
                v-if="item.mission_kind !== missionRank"
                class="ml-2 cl-font-14-bold w-14"
                :class="getClassTarget(item?.present, item?.target)"
              >
                {{ item?.present }}/{{ item?.target }}
              </div>
              <div v-else class="ml-2 w-14"></div>
              <v-btn
                v-if="item?.state !== state.ExpireTime"
                class="w-40 h-6 ml-7 mr-2"
                variant="outlined"
                :disabled="eventDetail?.event_state === state.ExpireTime"
                :color="getColorMission(item?.state, item)"
                @click="handleActionMission(item?.state, item)"
              >
                <span class="normal-case cl-font-14-bold">
                  {{ getTextMission(item?.state, item) }}
                </span>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <DialogGiff
    v-model="isShowDialogGiff"
    :info="rewardContent"
    @closeDialog="onCloseDialog"
  />
  <DialogArtifacts
    v-model="isShowDialogItems"
    :info="rewardContent"
    :unit="'GP'"
    :keyMoney="'GPoint'"
    :keySaleMoney="'ItemGPoint'"
    @closeDialog="onCloseDialog"
    @acceptDialog="onAcceptDialogArtifacts"
  />
  <DialogInGame
    v-model="isShowDialogInGame"
    :info="rewardContent"
    :unit="'GP'"
    :keyMoney="'GPoint'"
    :keySaleMoney="'ItemGPoint'"
    @closeDialog="onCloseDialog"
    @acceptDialog="onAcceptDialogIngame"
  />
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { key } from "@/store";
import constants from "@/constants";

// component
import DialogGiff from "@/components/core/dialog/reward/Gift.vue";
import DialogArtifacts from "@/components/core/dialog/shop/Artifacts.vue";
import DialogInGame from "@/components/core/dialog/shop/InGame.vue";

// router
import { useRouter, useRoute } from "vue-router";
import routesPath from "@/routers/routesPath";

// service
import { MissionService } from "@/modules/missions/services/missionServices";

// storage
import { getUserInfo } from "@/helpers/storage";

// i18n
import { useI18n } from "vue-i18n";

// TODO: remove
import imageGpoint from "@/assets/images/icons/GP_100x100.svg";

export default {
  components: {
    DialogGiff,
    DialogInGame,
    DialogArtifacts,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore(key);
    const { t } = useI18n();
    const state = constants.MISSION_STATE;
    const missionRank = 9;
    const imgGpoint = ref(imageGpoint);
    const eventDetail = ref({});
    const isShowDetail = ref(false);
    const isShowDialogGiff = ref(false);
    const isShowDialogItems = ref(false);
    const isShowDialogInGame = ref(false);
    const lstReward = ref([]);

    const rewardContent = ref({});

    const eventState = ref([
      {
        state: state.Unfulfilled,
        text: t("mission.event_state.unfulfilled"),
        bgColor: "bg-customPink",
        handleAction: () => joinEvent(),
      },
      {
        state: state.Progress,
        text: t("mission.event_state.progress"),
        bgColor: "bg-white/[0.2]",
        handleAction: () => {
          console.log("Đang tham gia");
        },
      },
      {
        state: state.ReceivedAll,
        text: t("mission.event_state.receivedAll"),
        bgColor: "bg-white/[0.2]",
        handleAction: () => {
          console.log("Kết thúc");
        },
      },
      {
        state: state.Complete,
        text: t("mission.event_state.complete"),
        bgColor: "bg-customGreen3",
        handleAction: () => rewardEvent(),
      },
      {
        state: state.ExpireTime,
        text: t("mission.event_state.receivedAll"),
        bgColor: "bg-white/[0.2]",
        handleAction: () => {
          console.log("Kết thúc");
        },
      },
      {
        state: state.CannotReceive,
        text: t("mission.event_state.cannotReceive"),
        bgColor: "bg-white/[0.2]",
        handleAction: () => {
          console.log("hết phần thưởng");
        },
      },
    ]);

    const missionState = ref([
      {
        state: state.Unfulfilled,
        text: t("mission.mission_state.unfulfilled"),
        color: "customPurple",
        handleAction: (val) => choiceMission(val),
      },
      {
        state: state.Progress,
        text: t("mission.mission_state.progress"),
        textReward: t("mission.mission_state.complete"),
        color: "customYellow2",
        colorReward: "customGreen3",
        handleAction: (val) => handleMissionProgress(val),
      },
      {
        state: state.ReceivedAll,
        text: t("mission.mission_state.receivedAll"),
        color: "customGreen3",
        handleAction: () => {
          console.log("Hoàn thành");
        },
      },
      {
        state: state.Complete,
        text: t("mission.mission_state.complete"),
        color: "customGreen3",
        handleAction: (val) => handleMissionComplete(val),
      },
    ]);

    const categoryDialog = [
      {
        category: constants.CATEGORY.artifacts,
        handleAction: () => showDialogItems(),
      },
      {
        category: constants.CATEGORY.shopMall,
        handleAction: () => showDialogItems(),
      },
      {
        category: constants.CATEGORY.box,
        handleAction: () => showDialogInGame(),
      },
    ];

    const actionMqtt = ref([
      {
        action: "update_user_mission_event_state",
        handleAction: (val) => handleEventState(val),
      },
      {
        action: "update_user_mission_event_reward",
        handleAction: (val) => handleEventReward(val),
      },
      {
        action: "update_user_mission_state",
        handleAction: (val) => handleMissionState(val),
      },
      {
        action: "update_user_mission_reward",
        handleAction: (val) => handleMissionReward(val),
      },
    ]);

    function handleEventState(data) {
      eventDetail.value.event_state = data?.event_state;
    }

    function handleMissionState(data) {
      eventDetail.value.mission_info = data;
    }

    function handleMissionReward(data) {
      let mission = eventDetail.value.mission_info.find(
        (it) => it.mission_id === data.mission_id
      );
      if (mission) {
        mission.is_receive_reward = data.is_receive_reward;
        mission.state = data.state;
      }
      if (data?.content && data?.content?.length) {
        data.content.forEach((item) => {
          handleReward(item?.reward, data?.mission_name);
        });
        setRewardContent();
      }
    }

    function setQuantity(data) {
      eventDetail.value.event_info.reward.items.quantity = data || 0;
    }

    function handleEventReward(data) {
      handleReward(data?.event_info?.reward, data?.event_info.event_name);
      if (checkShowQuantity()) {
        setQuantity(data?.event_info?.reward?.items?.quantity);
      }
      setRewardContent();
    }

    function handleReward(reward, eventName) {
      for (const key in reward) {
        let rewardContent = {};
        rewardContent.title = t("mission.complete_event");
        rewardContent.event = eventName;
        switch (key) {
          case "g_point":
            rewardContent.name = `${reward[key]} GP`;
            rewardContent.image = imgGpoint.value;
            rewardContent.action = () => showDialogGiff();
            break;

          case "items":
            if (reward[key]?.item_title) {
              rewardContent.userRewardId = reward[key]?.user_reward_id;
              rewardContent.name = reward[key]?.item_title;
              rewardContent.image = reward[key]?.image_url;
              rewardContent.ItemSubcategoryId = parseInt(
                reward[key]?.item_id.toString().substring(0, 4)
              );
              rewardContent.action = () => {
                const dialog = categoryDialog.find(
                  (it) =>
                    it.category.toString() ===
                    reward[key]?.item_id.toString().substring(0, 2)
                );
                if (dialog) {
                  dialog.handleAction();
                } else {
                  showDialogGiff();
                }
              };
            }
            break;

          default:
            break;
        }

        if (rewardContent.name) {
          lstReward.value.push(rewardContent);
        }
      }
    }

    function setRewardContent() {
      if (lstReward.value.length) {
        rewardContent.value = lstReward.value[0];
        rewardContent.value.action();
      }
    }

    function onCloseDialog() {
      isShowDialogGiff.value = false;
      isShowDialogInGame.value = false;
      isShowDialogItems.value = false;
      lstReward.value.splice(0, 1);
      setRewardContent();
    }

    async function onAcceptDialogArtifacts(data) {
      try {
        const payload = {
          UserRewardId: rewardContent.value.userRewardId,
          UserDisplayName: data.ReceiverName,
          PhoneNumber: data.ReceiverPhoneNumber ?? "",
          Address: data.ReceiverAddress ?? "",
        };
        await MissionService.putUserReward(payload);
        store.dispatch("setSnackBar", {
          show: true,
          message: "Gửi thông tin thành công",
          type: constants.TYPE_SNACK_BAR.SUCCESS,
        });
      } catch (error) {
        console.log(error);
      } finally {
        onCloseDialog();
        setRewardContent();
      }
    }

    async function onAcceptDialogIngame(data) {
      try {
        const payload = {
          UserRewardId: rewardContent.value.userRewardId,
          Email: data.EmailLink,
          PersonalUrl: data.EmailLink,
          PhoneNumber: data.PhoneNumber,
        };
        await MissionService.putUserReward(payload);
        store.dispatch("setSnackBar", {
          show: true,
          message: "Gửi thông tin thành công",
          type: constants.TYPE_SNACK_BAR.SUCCESS,
        });
      } catch (error) {
        console.log(error);
      } finally {
        onCloseDialog();
        setRewardContent();
      }
    }

    function showDialogGiff() {
      isShowDialogGiff.value = true;
    }

    function showDialogItems() {
      isShowDialogItems.value = true;
    }

    function showDialogInGame() {
      isShowDialogInGame.value = true;
    }

    async function getEventDetail() {
      try {
        const eventId = route.params.eventId;
        const res = await MissionService.getDetailEvent(eventId);
        eventDetail.value = res.Data;
      } catch (error) {
        console.log(error);
      }
    }

    function getTimeEvent(startTime, endTime) {
      if (!startTime || !endTime) {
        return;
      }
      const start = new Date(startTime * 1000).toLocaleDateString("en-GB");
      const end = new Date(endTime * 1000).toLocaleDateString("en-GB");
      return `${start} - ${end}`;
    }

    function getTextEvent(state) {
      const item = eventState.value.find((it) => it.state === state);
      if (item) {
        return item.text;
      }
      return "";
    }

    function getBgColorEvent(state) {
      const item = eventState.value.find((it) => it.state === state);
      if (item) {
        return item.bgColor;
      }
      return "";
    }

    function handleActionEvent(state) {
      const item = eventState.value.find((it) => it.state === state);
      if (item) {
        return item.handleAction();
      }
      return "";
    }

    function getTextMission(stateMission, mission) {
      const item = missionState.value.find((it) => it.state === stateMission);
      if (item) {
        return stateMission === state.Progress && mission.is_receive_reward
          ? item.textReward
          : item.text;
      }
      return "";
    }

    function getColorMission(stateMission, mission) {
      const item = missionState.value.find((it) => it.state === stateMission);
      if (item) {
        return stateMission === state.Progress && mission.is_receive_reward
          ? item.colorReward
          : item.color;
      }
      return "";
    }

    function handleActionMission(state, mission) {
      const item = missionState.value.find((it) => it.state === state);
      if (item) {
        return item.handleAction(mission);
      }
      return "";
    }

    function handleMissionProgress(data) {
      if (data.is_receive_reward) {
        rewardMission(data.mission_id);
      }
    }

    function handleMissionComplete(data) {
      rewardMission(data.mission_id);
    }

    async function choiceMission(mission) {
      try {
        if (eventDetail.value.event_state === state.ExpireTime) {
          return;
        }
        const eventId = route.params.eventId;
        await MissionService.choiceMission(
          parseInt(eventId),
          mission.mission_id
        );
      } catch (error) {
        store.dispatch("setSnackBar", {
          show: true,
          message: t(`error_code[${error}]`),
          type: constants.TYPE_SNACK_BAR.ERROR,
        });
      }
    }

    async function rewardEvent() {
      try {
        const eventId = route.params.eventId;
        await MissionService.rewardEvent(eventId);
      } catch (error) {
        store.dispatch("setSnackBar", {
          show: true,
          message: t(`error_code[${error}]`),
          type: constants.TYPE_SNACK_BAR.ERROR,
        });
      }
    }

    async function rewardMission(missionId) {
      try {
        const eventId = route.params.eventId;
        await MissionService.rewardMission(eventId, missionId);
      } catch (error) {
        store.dispatch("setSnackBar", {
          show: true,
          message: t(`error_code[${error}]`),
          type: constants.TYPE_SNACK_BAR.ERROR,
        });
      }
    }

    async function joinEvent() {
      try {
        const eventId = route.params.eventId;
        await MissionService.joinEvent(eventId);
      } catch (error) {
        store.dispatch("setSnackBar", {
          show: true,
          message: t(`error_code[${error}]`),
          type: constants.TYPE_SNACK_BAR.ERROR,
        });
      }
    }

    function getClassTarget(present, target) {
      return present == target ? "text-customGreen3" : "text-customPurple";
    }

    function getHeightMission() {
      if (process.env.VUE_APP_BUILD === "DESKTOP-APP") {
        return "calc(100vh - 185px)";
      }
      return "calc(100vh - 160px)";
    }

    function onClickBack() {
      router.push(routesPath.EVENTS_PATH);
    }

    function checkShowEventFinal() {
      if (eventDetail.value.event_info) {
        const gpoint = eventDetail.value.event_info.reward.g_point;
        const itemId = eventDetail.value.event_info.reward.items.item_id;
        return gpoint || itemId;
      }
      return false;
    }

    function checkShowQuantity() {
      const { event_info } = eventDetail.value;
      if (!event_info) return false;
      const itemId = event_info?.reward?.items?.item_id;

      const validItemIds = [
        constants.CATEGORY.artifacts,
        constants.CATEGORY.box,
        constants.CATEGORY.shopMall,
      ];
      return (
        itemId &&
        validItemIds.includes(parseInt(itemId.toString().substring(0, 2)))
      );
    }

    return {
      state,
      missionRank,
      actionMqtt,
      eventDetail,
      rewardContent,
      isShowDetail,
      isShowDialogGiff,
      isShowDialogItems,
      isShowDialogInGame,
      getClassTarget,
      getEventDetail,
      getTextEvent,
      getTimeEvent,
      getBgColorEvent,
      handleActionEvent,
      getTextMission,
      getColorMission,
      getHeightMission,
      handleActionMission,
      onCloseDialog,
      onClickBack,
      onAcceptDialogArtifacts,
      onAcceptDialogIngame,
      checkShowEventFinal,
      checkShowQuantity,
    };
  },

  created() {
    this.getEventDetail();
    this.onListenMessageMqtt();
    setTimeout(() => {
      this.isShowDetail = true;
    }, 300);
  },

  methods: {
    onListenMessageMqtt() {
      const userInfo = JSON.parse(getUserInfo());
      const topicRankingUser = `${process.env.VUE_APP_TOPIC_RANKING_USER}${userInfo?.user_id}`;

      this.$clientMqtt.on("message", async (topic, message) => {
        if (topic !== topicRankingUser) {
          return;
        }
        const decoder = new TextDecoder("utf-8");
        let { Action, Data } = JSON.parse(decoder.decode(message));
        console.log(Action);
        console.log(Data);
        const action = this.actionMqtt.find((it) => it.action === Action);
        if (action) {
          action.handleAction(Data);
        }
      });
    },

    onRedirectLink(link) {
      if (!link) return;
      window.location.href = link;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-list-item {
  padding: 0px !important;
}
::v-deep(.v-progress-linear__determinate) {
  border-radius: 3px;
  background: linear-gradient(270deg, #63c281 0%, #2f5c3d 100%) !important;
}
::v-deep(.v-progress-linear__buffer) {
  background: linear-gradient(90deg, #502f9f 0%, #7b72c6 100%) !important;
}
::v-deep(.v-list-item__spacer) {
  width: 0px !important;
}

.quantity-item {
  border: 1px solid #7b72c6;
  border-radius: 10px;
  text-align: center;
  line-height: 22px;
}
</style>
