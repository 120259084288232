<template>
  <div class="cl-app-body">
    <Transition mode="out-in" name="slide-fade">
      <router-view />
    </Transition>
  </div>
</template>

<script lang="ts">
export default {
  name: "App",
  created() {
    console.log(`\n\t\t\t
    🚀 Welcome to G-Battle system 🚀
    \n\n\n`);
  },
};
</script>
