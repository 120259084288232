<template>
  <div class="z-10 mt-4 ma-2 flex justify-between">
    <v-btn
      icon
      class="ml-2 z-10"
      size="40"
      color="transparent"
      @click="onClickBack"
    >
      <v-icon class="mt-n1" size="40" color="rgba(255, 255, 255, 0.8)">
        mdi-arrow-left-bold-circle
      </v-icon>
    </v-btn>
    <v-btn
      v-if="isShowBtnLogout()"
      class="z-10"
      width="120"
      height="34"
      variant="outlined"
      color="#FF507A"
      :loading="loading"
      @click="onLogout"
    >
      <span class="cl-font-14-bold normal-case mr-2">Đăng xuất</span>
      <img src="@/assets/images/btn/btn_leave.svg" alt="" srcset="" />
    </v-btn>
  </div>
  <div class="w-screen noselect absolute top-0">
    <div class="cl-bg-image">
      <img src="https://cdn-data.gbattle.vn/image/app/bg_user.svg" alt="" />
    </div>
    <ProfilePlayer :user-info="userInfo" :loading-info="loadingInfo" />
    <div class="cl-tabs">
      <v-select
        v-model="selectGame"
        class="cl-select mr-6"
        width="190"
        height="40"
        density="compact"
        variant="plain"
        hide-no-data
        hide-details
        menu-icon=""
        auto
        attach
        :items="menuGame"
        item-title="name"
        item-value="code"
      >
        <template v-slot:append-inner>
          <v-icon class="mr-1" color="#7B72C6">mdi-chevron-down</v-icon>
        </template>
        <template v-slot:item="{ props, item }">
          <div>
            <v-list-item v-bind="props">
              <template v-slot:prepend>
                <img
                  class="mr-2"
                  src="@/assets/images/remove/ic_cs2.svg"
                  alt=""
                  srcset=""
                />
              </template>
              <template v-slot:title>
                <div class="cl-font-18-bold text-white">
                  {{ item.raw.name }}
                </div>
              </template>
            </v-list-item>
          </div>
        </template>
        <template v-slot:selection="{ item }">
          <div class="cl-custom-selection cl-font-14-bold">
            <img
              class="w-4"
              src="@/assets/images/remove/ic_cs2.svg"
              alt=""
              srcset=""
            />
            <span>{{ item.raw.name }} </span>
          </div>
        </template>
      </v-select>
      <div
        v-for="(item, index) in tabItems"
        v-show="checkRule(item)"
        :key="index"
        :class="
          item.value === itemActive.value
            ? 'border-t-4 border-customPurple2'
            : 'border-t-4 border-transparent'
        "
        class="cl-tab-item cl-font-16-bold text-white"
        @click="onChangeTab(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <Transition mode="out-in" name="slide-fade">
      <component
        :is="itemActive.data"
        :user-info="userInfo"
        :loading-info="loadingInfo"
      />
    </Transition>
  </div>
</template>

<script>
import constants from "@/constants";

// components
import ProfilePlayer from "./view/ProfilePlayer.vue";
import Statistic from "./view/Statistic.vue";
import Inventory from "./view/Inventory.vue";
import InforPlayer from "./view/InforPlayer.vue";
import HistoryTransaction from "./view/HistoryTransaction.vue";

// storage
import { clearStorageWhenLogout, getUserInfo } from "@/helpers/storage";

// service
import { AuthService } from "@/services/authService";

// router
import routersBattleCs2 from "@/modules/battleCS2/routers/routesPath";

export default {
  name: "profileUser",

  components: {
    ProfilePlayer,
    Statistic,
    Inventory,
    InforPlayer,
    HistoryTransaction,
  },

  data() {
    return {
      loading: false,
      loadingInfo: false,
      modelTab: null,
      selectGame: "cs2",
      userInfo: null,
      menuGame: [
        {
          name: "Counter Strike 2",
          code: "cs2",
        },
      ],
      itemActive: null,
      // TODO: open
      tabItems: [
        {
          value: constants.COMPONET_PROFILE.statistic,
          name: "Thống kê",
          data: Statistic,
        },
        {
          value: constants.COMPONET_PROFILE.inventory,
          name: "Hòm đồ",
          data: Inventory,
        },
        {
          value: constants.COMPONET_PROFILE.inforPlayer,
          name: "Thông tin",
          data: InforPlayer,
        },
        {
          value: constants.COMPONET_PROFILE.historyTransaction,
          name: "Lịch sử giao dịch",
          data: HistoryTransaction,
        },
      ],
    };
  },

  watch: {
    "$route.params.userId"() {
      this.$router.go();
    },
    "$route.query.tabItem"() {
      const tabItem = this.$route.query?.tabItem || 0;
      this.onChangeTab(this.tabItems[tabItem]);
    },
    upateInfoPlayer() {
      if (this.$store.state.upateInfoPlayer) {
        const userInfo = JSON.parse(getUserInfo());
        this.userInfo = userInfo;
      }
    },
  },

  computed: {
    snackbarFindMatch() {
      return this.$store.state.snackBarFindMatch;
    },
    upateInfoPlayer() {
      return this.$store.state.upateInfoPlayer;
    },
  },

  created() {
    const tabItem = this.$route.query?.tabItem || 0;
    this.onChangeTab(this.tabItems[tabItem]);
  },

  mounted() {
    this.getUserInfo();
  },

  methods: {
    isShowBtnLogout() {
      return process.env.VUE_APP_BUILD === "WEB";
    },
    checkRule(item) {
      const userInfo = JSON.parse(getUserInfo());
      switch (item.value) {
        case constants.COMPONET_PROFILE.statistic:
          return true;
        case constants.COMPONET_PROFILE.inventory:
          return userInfo?.user_id === this.$route.params.userId;
        case constants.COMPONET_PROFILE.inforPlayer:
          return userInfo?.user_id === this.$route.params.userId;
        case constants.COMPONET_PROFILE.historyTransaction:
          return userInfo?.user_id === this.$route.params.userId;
        default:
          break;
      }
    },

    onChangeTab(item) {
      this.itemActive = item;
    },

    onClickBack() {
      if (this.snackbarFindMatch) {
        this.$router.push({
          path: routersBattleCs2.ROOM_BATTLE_PATH,
        });
        return;
      }
      this.$router.go(-1);
    },

    async onLogout() {
      try {
        this.loading = true;
        await AuthService.logout();
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
        clearStorageWhenLogout();
        this.$router.push(constants.LOGIN_PATH);
      }
    },

    async getUserInfo() {
      try {
        this.loadingInfo = true;
        const payload = {
          userId: this.$route.params.userId,
        };
        const res = await AuthService.getUserInfo(payload);
        this.userInfo = res.Data;
      } catch (error) {
        console.log(error);
      } finally {
        setTimeout(() => {
          this.loadingInfo = false;
        }, 500);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.v-list) {
  background-color: rgba(80, 47, 159, 1) !important;
  border: 1px solid rgba(123, 114, 198, 1);
}

.cl-bg-image {
  width: 100vw;
  height: 280px;
  z-index: -1;
  border-bottom: 1px solid rgba(80, 47, 159, 1);
  position: relative;
  img {
    width: 100%;
    height: 100%;
    top: 0px;
    z-index: -1;
    position: absolute;
    object-fit: cover;
  }
}

.cl-tabs {
  width: max-content;
  height: 55px;
  margin: -39px 0px 0px 248px;
  display: flex;
  .cl-select {
    width: 180px;
    height: 40px;
    position: relative;
    margin-top: 8px;
    border-radius: 3px;
    background: transparent;
    border: 1px solid rgba(80, 47, 159, 1);
  }
  .cl-tab-item {
    width: 200px;
    height: 42px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.cl-custom-selection {
  display: flex;
  gap: 10px;
  width: 180px;
  height: 40px;
  white-space: nowrap;
  padding-left: 10px;
  margin-top: -10px;
  align-items: center;
  justify-content: flex-start;
  color: rgba(255, 255, 255, 1);
}

.cl-custom-item {
  background: rgba(63, 39, 120, 1);
  color: rgba(255, 255, 255, 1);
  border-radius: 4px !important;
}
</style>
