<template>
  <v-dialog v-model="showDialog" max-width="500" persistent>
    <div class="cl-dialog noselect">
      <img
        class="cl-bg"
        src="@/assets/images/dialog/bg_count_down.svg"
        alt=""
      />
      <div class="cl-title">
        {{ success ? "Mua thành công" : info.ItemTitle }}
      </div>
      <img class="w-40 mb-6" :src="info.ImageUrl" alt="" />
      <div
        v-if="itemActive.id !== constants.SHOP_ITEMS.fashion && !success"
        class="text-white cl-font-18-bold mb-2 flex items-center gap-2"
      >
        Số lượng
        <v-text-field
          v-model="quantity"
          class="w-16 h-10"
          density="compact"
          type="number"
          color="#502F9F"
          variant="outlined"
          hide-spin-buttons
          hide-details
        ></v-text-field>
      </div>
      <div v-if="success" class="cl-font-18-bold text-white">
        Bạn đã mua
        <span class="text-customYellow2">[{{ info.ItemTitle }}]</span>
      </div>
      <div v-if="success" class="cl-font-14-normal text-white">
        Tới Hòm đồ trong Trang cá nhân để sử dụng vật phẩm
      </div>
      <v-btn
        v-if="!success"
        width="24"
        height="24"
        class="absolute right-3 top-3"
        icon
        variant="plain"
        color="#ffffff"
        @click="onCloseDialog()"
      >
        <v-icon size="24">mdi-close</v-icon>
      </v-btn>
      <v-btn
        class="cl-btn-accept absolute bottom-0 mb-4"
        :class="success ? '' : 'bg-customGreen'"
        @click="onAccept()"
      >
        <span class="cl-font-24-bold"> {{ success ? "OK" : "MUA" }} </span>
      </v-btn>
    </div>
  </v-dialog>
</template>

<script>
import constants from "@/constants";

export default {
  name: "dialogTransaction",
  emit: ["closeDialog", "acceptDialog"],

  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
    info: {
      type: Object,
      default: undefined,
    },
    success: {
      type: Boolean,
      default: false,
    },
    itemActive: {
      type: Object,
      default: undefined,
    },
  },

  data() {
    return {
      constants,
      showDialog: this.value,
      quantity: 1,
    };
  },

  watch: {
    showDialog(val, oldVal) {
      if (val === oldVal) return;
    },
    value(val, oldVal) {
      if (val === oldVal) return;
      this.showDialog = val;
    },
  },

  methods: {
    onCloseDialog() {
      this.$emit("closeDialog");
      this.quantity = 1;
    },

    onAccept() {
      const quantity = this.quantity;
      this.$emit("acceptDialog", quantity);
      this.quantity = 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.cl-dialog {
  width: 500px;
  min-height: 450px;
  height: max-content;
  padding: 10px;
  .cl-title {
    position: absolute;
    top: 40px;
  }
  .cl-bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .cl-btn-accept {
    width: 250px;
    height: 60px;
    bottom: 40px;
    position: absolute;
  }
}
</style>