<template>
  <div class="noselect overflow-hidden">
    <div
      v-if="isShowGShield"
      class="absolute z-10 w-screen h-4/5 flex justify-center items-center"
    >
      <img src="@/assets/images/g_shield.svg" alt="" srcset="" />
      <div
        class="ml-28 mt-8 flex justify-center items-center absolute cl-font-14-bold start-up"
      ></div>
      <div class="text-white absolute ml-24 mt-20 cl-font-14-normal">
        Vui lòng không tắt phần mềm
      </div>
    </div>
    <div class="cl-gbattle-home noselect" :style="{ height: getHeightHome() }">
      <div class="cl-home-left">
        <!-- action -->
        <v-btn
          variant="text"
          class="cl-btn-play-now cl-polygon cl-font-32-bold noselect"
          :disabled="loading"
          @click="onClickPlayNow"
        >
          <div class="cl-bkg">
            <img src="@/assets/images/btn/btn_play_now.svg" alt="" />
          </div>
          {{ $t("battle.btn_ranking") }}
          <div class="cl-btn-polygon">
            <v-skeleton-loader
              class="cursor-pointer"
              color="transparent"
              type="card"
            ></v-skeleton-loader>
          </div>
        </v-btn>
        <v-btn
          variant="plain"
          class="cl-btn-play-now cl-polygon cl-font-20-bold noselect mt-5"
          @click="onPlayNormal"
        >
          <div class="cl-bkg">
            <img src="@/assets/images/btn/btn_normal.svg" alt="" />
          </div>
          {{ $t("battle.btn_normal") }}
        </v-btn>
        <!-- Upcoming tournament -->
        <div class="cl-upcoming-tournament">
          <div>
            <div
              v-for="(item, index) in tournamentInfo"
              :key="index"
              class="cl-info mb-4"
              @click.stop="onClickBanner(item)"
            >
              <BaseLazyImage
                :src="item.ImageUrl"
                :target-url="item.TargetUrl"
                width="320"
                height="180"
              />
              <div class="cl-name cl-font-18-bold">
                <div class="flex-1 maxline1">{{ item.Title }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- content -->
      <div class="cl-home-center">
        <div
          class="cl-content"
          v-for="(item, index) in news"
          :key="index"
          @click.stop="onClickBanner(item)"
        >
          <BaseLazyImage
            :src="item.ImageUrl"
            :target-url="item.TargetUrl"
            :minWidth="599"
            :minHeight="366"
          />
          <div class="cl-news-text" v-html="item.Description"></div>
          <div v-if="index < news.length - 1" class="cl-divider">
            <img src="@/assets/images/divider_end.svg" alt="" />
          </div>
        </div>
      </div>
      <!-- ads -->
      <div class="cl-home-right">
        <div
          v-for="(item, index) in ads"
          :key="item + index"
          class="mb-2 w-84"
          @click.stop="onClickBanner(item)"
        >
          <BaseLazyImage
            :src="item.ImageUrl"
            :target-url="item.TargetUrl"
            width="350"
            height="230"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// constant
import constants from "@/constants";

// service
import { BattleService } from "./services/battleServices";
import { EventService } from "@/services/eventService";

// router
import routersBattle from "./routers/routesName";

// mixins
import Cs2Party from "@/modules/battleCS2/mixins/party";

// sotorage
// import { getGshield, setGshield } from "@/helpers/storage";

export default {
  name: "gBattleModule",
  mixins: [Cs2Party],

  data() {
    return {
      isShowGShield: false,
      loading: false,
      // TODO
      tournamentInfo: [],
      news: [],
      ads: [],
    };
  },

  mounted() {
    this.getBanners();
    // const gShield = JSON.parse(getGshield());
    // if (gShield) {
    //   this.isShowGShield = gShield;
    //   setTimeout(() => {
    //     this.isShowGShield = false;
    //     setGshield(false);
    //   }, 4000);
    // }
  },

  methods: {
    getHeightHome() {
      if (process.env.VUE_APP_BUILD == "DESKTOP-APP") {
        return "calc(100vh - 85px)";
      }
      return "calc(100vh - 60px)";
    },

    async getBanners() {
      try {
        const [bannersLeft, bannersCenter, bannersRight] = await Promise.all([
          BattleService.getBanbers(600),
          BattleService.getBanbers(601),
          BattleService.getBanbers(602),
        ]);
        this.tournamentInfo = bannersLeft.Data;
        this.news = bannersCenter.Data;
        this.ads = bannersRight.Data;
      } catch (error) {
        console.log(error);
      }
    },

    async onClickBanner(banner) {
      try {
        const payload = {
          event_name: "click_banner",
          data: {
            campaign_id: banner.Id,
            banner_id: banner.IdBanner,
            banner_type: "gbattle",
          },
        };
        EventService.postlogApp(payload);
      } catch (error) {
        console.log(error);
      }
    },

    async onClickPlayNow() {
      // TODO: WEB_PROD
      if (process.env.VUE_APP_BUILD == "DESKTOP-APP") {
        this.loading = true;
        try {
          const infoParty = await this.getInfoParty();
          if (!infoParty) {
            await BattleService.createParty({
              game_name: "cs2",
              party_mode: constants.PARTY_MODE.RANKING,
            });
          }
          this.$router.push({
            name: routersBattle.ROOM_BATTLE,
          });
        } catch (error) {
          this.$store.dispatch("setSnackBar", {
            show: true,
            message: this.$t(`error_code[${error}]`),
            type: constants.TYPE_SNACK_BAR.ERROR,
          });
        } finally {
          this.loading = false;
        }
      }
    },

    async onPlayNormal() {
      // TODO: WEB_PROD
      if (
        process.env.VUE_APP_BUILD !== "DESKTOP-APP" ||
        !this.checkAntiCheat()
      ) {
        this.downloadGplay();
        return;
      }
      this.$router.push({
        name: routersBattle.NORMAL,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.cl-gbattle-home {
  width: 100vw;
  padding: 20px 0px 0px 20px;
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
  justify-content: space-between;
  overflow: hidden;
}
.cl-home-left {
  width: max-content;
  max-width: 360px;
  .cl-upcoming-tournament {
    height: calc(100vh - 250px);
    margin-top: 20px;
    padding: 20px 5px 40px 0px;
    overflow-y: auto;
    border-radius: 1px;
    background: linear-gradient(
      270deg,
      rgba(39, 24, 76, 0.5) 0%,
      rgba(39, 24, 76, 0) 100%
    );
    .cl-title {
      display: flex;
      align-items: center;
      gap: 10px;
      color: #7b72c6;
    }
    .cl-info {
      img {
        width: 320px;
        height: 180px;
        object-fit: cover;
        border-radius: 10px 0px 0px 0px;
      }
    }
    .cl-name {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #ffffff;
      .cl-time {
        width: max-content;
        white-space: nowrap;
        height: 20px;
        padding: 1px;
        border-radius: 3px;
        color: #7b72c6;
        border: 1px solid #7b72c6;
      }
    }
  }
  .cl-btn-play-now {
    width: 300px;
    height: 60px;
    color: #ffffff;
    margin-left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    text-transform: uppercase;
    .cl-bkg {
      width: 300px;
      z-index: -1;
      position: absolute;
    }
  }
}
.cl-home-center {
  flex: 1;
  min-width: 600px;
  height: 100%;
  gap: 36px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-right: 5px;
  .cl-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .cl-news-text {
      width: 100%;
      color: #ffffff;
      padding: 10px 13px 10px 13px;
      margin: 10px 0px 26px 0px;
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.05);
    }
    .v-lazy {
      width: 100%;
      min-height: max-content;
    }
    img {
      width: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
    .cl-divider {
      width: 208px;
    }
  }
}
.cl-home-right {
  width: max-content;
  margin-top: -15px;
  padding: 0px 5px 30px 0px;
  right: 20px;
  height: max-content;
  overflow: hidden;
  overflow-y: auto;
  img {
    width: 350px;
    height: 230px;
    object-fit: cover;
    border-radius: 10px;
  }
}
.start-up:before {
  content: "Đang khởi động...";
  position: absolute;
  overflow: hidden;
  max-width: 11em;
  white-space: nowrap;
  color: #ffffff;
  animation: loading 2s linear infinite;
}
.cl-btn-polygon {
  width: 300px;
  height: 60px;
  z-index: 10;
  top: 0px;
  right: 0px;
  overflow: hidden;
  position: absolute;
  clip-path: polygon(6% 0%, 94% 0%, 100% 50%, 94% 100%, 6% 100%, 0% 50%);
}
@keyframes loading {
  0% {
    max-width: 0;
  }
}
</style>