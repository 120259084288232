import { createApp } from 'vue'
import App from './App.vue'
import { store, key } from './store'
import routers from './routers';
// import upperFirst from 'lodash/upperFirst';
// import camelCase from 'lodash/camelCase';
import vuetify from './plugins/vuetify';
import { mqttPlugin } from './plugins/clientMqtt';
import './helpers/global';
import './assets/css/tailwind.css';
import i18n from './i18n';
import { setStore } from '@/services/baseService';

const app = createApp(App);

const requireComponent = require.context('@/components/base', true, /\.vue$/);

requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);
  // const componentName = upperFirst(camelCase(fileName.replace(/^\.\//, '').replace(/\.\w+$/, '')));
  // Đặt tên cho component
  const componentName = fileName.replace(/^\.\//, '').replace(/\.\w+$/, '');
  const name = `Base${componentName}`;
  // Đăng ký component với Vue
  app.component(name, componentConfig.default || componentConfig);
});

if (process.env.VUE_APP_BUILD == "DESKTOP-APP") {
  // eslint-disable-next-line
  const requireWindowBar = require('@/components/core/WindowBar.vue')
  app.component(`BaseWindowBar`, requireWindowBar.default || requireWindowBar);
}

app.use(store, key);
app.use(routers);
app.use(vuetify);
app.use(i18n);
app.use(mqttPlugin);

app.mount('#app');

setStore(store);

// Yêu cầu quyền thông báo ngay khi ứng dụng khởi động
if ('Notification' in window && Notification.permission !== 'granted') {
  Notification.requestPermission().then(permission => {
    if (permission === 'granted') {
      console.log('Notification permission granted.');
    } else {
      console.log('Notification permission denied.');
    }
  });
}
