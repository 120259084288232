<template>
  <div class="cl-items-player">
    <div v-for="subCategory in lstSubCategory" :key="subCategory.title">
      <div
        class="h-9 bg-customBlack2 text-white cl-font-14-bold flex items-center pl-4 m rounded-lg"
      >
        {{ subCategory.title }}
      </div>
      <div class="flex flex-wrap gap-5 mt-5 mb-7">
        <div
          v-for="(item, index) in subCategory.lstItem"
          :key="index"
          style="width: 180px"
          class="cl-item h-58 cursor-pointer flex flex-col items-center justify-between bg-customPurple4 py-4 rounded-xxl pa-2 relative"
          @dblclick="$emit('buyItem', item)"
        >
          <div
            v-if="subCategory.category === constants.CATEGORY.box"
            class="flex flex-col justify-center flex-1 relative"
          >
            <div
              class="absolute top-0 right-0 w-5 h-5 rounded-full bg-customPurple2 text-customPurple cl-font-10-bold flex items-center justify-center"
            >
              {{
                parseInt(item.TotalQuantity) - parseInt(item.ReceivedQuantity)
              }}
            </div>
            <img :src="item.ImageUrl" alt="" srcset="" />
          </div>
          <div v-else class="rounded-full truncate w-38 h-38">
            <img class="w-full h-full" :src="item.ImageUrl" alt="" srcset="" />
          </div>
          <div
            v-if="subCategory.category !== constants.CATEGORY.box"
            class="flex mt-2 gap-2"
          >
            <!-- <img src="@/assets/images/icons/GIF.svg" alt="" srcset="" />
            <v-divider
              vertical
              class="border-opacity-25"
              color="info"
            ></v-divider> -->
            <!-- <img src="@/assets/images/icons/give_gif.svg" alt="" srcset="" /> -->
          </div>
          <div class="w-full flex flex-col">
            <div
              class="text-white mt-2 w-full cl-font-14-normal leading-4 maxline2 break-work"
            >
              {{ item.ItemTitle }}
            </div>
            <div class="w-full flex items-center gap-1">
              <div class="text-customYellow2 cl-font-14-bold">
                {{ formatterNumber(item[keySaleMoney]) }} {{ unit }}
              </div>
              <div
                v-if="item[keyMoney] > 0"
                class="text-customPurple cl-font-12-bold line-through"
              >
                {{ formatterNumber(item[keyMoney]) }}
                {{ unit }}
              </div>
            </div>
            <v-btn
              class="cl-btn-shop absolute right-2 bottom-2 bg-customGreen text-white cl-font-14-bold"
              height="22"
              variant="tonal"
              @click="$emit('buyItem', item)"
            >
              {{ genTextButton(subCategory.category) }}
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import constants from "@/constants";

export default {
  name: "ItemsPlayerVue",

  props: {
    data: {
      typeof: Array,
      default: [],
    },
    unit: {
      typeof: String,
      default: "Gạo",
    },
    keyMoney: {
      typeof: String,
      default: "ItemCoin",
    },
    keySaleMoney: {
      typeof: String,
      default: "ItemSaleCoin",
    },
    lstSubCategory: {
      typeof: Array,
      default: [],
    },
  },

  data() {
    return {
      constants,
    };
  },

  methods: {
    formatterNumber(amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "decimal",
      });
      return formatter.format(amount).replaceAll(",", ".");
    },

    genTextButton(subCategory) {
      const listCategoryBuy = [
        constants.SUB_CATEGORY.frameGB,
        constants.SUB_CATEGORY.avatarGB,
        constants.CATEGORY.supplement,
      ];
      return listCategoryBuy.includes(subCategory) ? "Mua" : "Nhận";
    },
  },
};
</script>

<style lang="scss" scoped>
.cl-items-player {
  min-width: 980px;
  .cl-item {
    &:hover {
      .cl-btn-using {
        display: block;
      }
    }
    .cl-btn-using {
      display: none;
    }
    .cl-btn-shop {
      display: none;
      border-radius: 4px;
      height: 30px;
    }
    &:hover {
      .cl-btn-shop {
        display: block;
      }
    }
  }
}
</style>
