import { BaseService } from "@/services/baseService";
import Api from "@/services/api"
import loginPayload from "@/types/login";
import registerPayload from "@/types/login";
import userParams from "@/types/friend";
import friendParams from "@/types/friend";
import searchParams from "@/types/friend";
import addFriendPayload from "@/types/friend";
import relationShipFriendPayload from "@/types/friend";

export class AuthService extends BaseService {
  static async login(payload: loginPayload) {
    try {
      const response = await this.request().post(Api.APP.LOGIN, payload)
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  static async logout() {
    try {
      const response = await this.request().post(Api.APP.LOGOUT)
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  static async register(payload: registerPayload) {
    try {
      const response = await this.request().post(Api.APP.REGISTER, payload)
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  static async getCaptcha() {
    try {
      const response = await this.request().get(Api.APP.CAPTCHA)
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  static async getUserInfo(params: userParams) {
    try {
      const response = await this.request().get(`${process.env.VUE_APP_GBATTLE_API}${Api.APP.USER_INFO}`, {
        params: params
      })
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  static async getUserFriends(params: friendParams) {
    try {
      const response = await this.request().get(Api.APP.FRIENDS, {
        params: params
      })
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  static async getUserPending() {
    try {
      const response = await this.request().get(Api.APP.FRIENDS_PENDING)
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  static async searchUserFriends(params: searchParams) {
    try {
      const response = await this.request().get(Api.APP.SEARCH_FRIENDS, {
        params: params
      })
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  static async addFriend(payload: addFriendPayload) {
    try {
      const response = await this.request().post(Api.APP.ADD_FRIEND, payload)
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  static async addRelationShipFriend(payload: relationShipFriendPayload) {
    try {
      const response = await this.request().put(Api.APP.RELATION_SHIP_FRIEND, payload)
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  static async searchUserAll(params: searchParams) {
    try {
      const response = await this.request().get(Api.APP.SEARCH_USER_ALL, {
        params: params
      })
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }
}