// store/index.ts
import { InjectionKey } from 'vue'
import { createStore, Store } from 'vuex'

// define your typings for the store state
export interface State {
  drawerFriend: boolean
  drawerChat: boolean
  drawerNotify: boolean
  dialogCountDown: object,
  snackBar: object,
  snackBarFindMatch: boolean,
  upateInfoParty: boolean,
  upateInfoPlayer: boolean,
  addPendingFriend: object | null
  isSessionExpired: boolean,
  isRingTheBell: boolean
}

// define injection key
export const key: InjectionKey<Store<State>> = Symbol()

export const store = createStore<State>({
  state: {
    drawerFriend: false,
    drawerChat: false,
    drawerNotify: false,
    dialogCountDown: {
      show: false,
      startTime: 59,
      gameId: "",
      numberAccept: 0,
    },
    snackBar: {
      show: false,
      message: "",
      type: ""
    },
    snackBarFindMatch: false,
    upateInfoParty: false,
    upateInfoPlayer: false,
    addPendingFriend: null,
    isSessionExpired: false,
    isRingTheBell: false

  },
  mutations: {
    SET_DRAWER_FRIEND(state, data) {
      state.drawerFriend = data
    },
    SET_DRAWER_CHAT(state, data) {
      state.drawerChat = data
    },
    SET_DRAWER_NOTIFY(state, data) {
      state.drawerNotify = data
    },
    SET_DIALOG_COUNTDOWN(state, data) {
      state.dialogCountDown = data
    },
    SET_SNACK_BAR(state, data) {
      state.snackBar = data
    },
    SET_SNACK_BAR_FIND_MATCH(state, data) {
      state.snackBarFindMatch = data
    },
    SET_UPDATE_INFO_PARTY(state, data) {
      state.upateInfoParty = data
    },
    SET_UPDATE_INFO_PLAYER(state, data) {
      state.upateInfoPlayer = data
    },
    SET_ADD_PENDING_FRIEND(state, data) {
      state.addPendingFriend = data
    },
    SET_IS_SESSION_EXPIRED(state, data) {
      state.isSessionExpired = data
    },
    SET_IS_RING_THE_BELL(state, data) {
      state.isRingTheBell = data
    }

  },
  actions: {
    setDrawerFriend({ commit }, data) {
      commit('SET_DRAWER_FRIEND', data)
    },
    setDrawerChat({ commit }, data) {
      commit('SET_DRAWER_CHAT', data)
    },
    setDrawerNotify({ commit }, data) {
      commit('SET_DRAWER_NOTIFY', data)
    },
    setDialogCountDown({ commit }, data) {
      commit('SET_DIALOG_COUNTDOWN', data)
    },
    setSnackBar({ commit }, data) {
      commit('SET_SNACK_BAR', data)
    },
    setSnackBarFindMatch({ commit }, data) {
      commit('SET_SNACK_BAR_FIND_MATCH', data)
    },
    setUpdateInfoParty({ commit }, data) {
      commit('SET_UPDATE_INFO_PARTY', data)
    },
    setUpdateInfoPlayer({ commit }, data) {
      commit('SET_UPDATE_INFO_PLAYER', data)
    },
    setAddPendingFriend({ commit }, data) {
      commit('SET_ADD_PENDING_FRIEND', data)
    },
    setIsSessionExpired({ commit }, data) {
      commit('SET_IS_SESSION_EXPIRED', data)
    },
    setIsRingTheBell({ commit }, data) {
      commit('SET_IS_RING_THE_BELL', data)
    }

  }
})