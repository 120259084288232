import constants from '@/constants';
import routesName from './routesName';
import routesPath from './routesPath';
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

// component
import Room from '@/modules/battleCS2/view/room.vue';
import Lobby from '@/modules/battleCS2/view/lobby.vue';
import FindTeam from '@/modules/battleCS2/view/findTeam.vue';
import Ranking from '@/modules/battleCS2/view/ranking.vue';
import PlayNormal from '@/modules/battleCS2/view/playNormal.vue';

export const routersBattle = [
  {
    name: routesName.ROOM_BATTLE,
    path: routesPath.ROOM_BATTLE_PATH,
    component: Room
  },
  {
    name: routesName.LOBBY_BATTLE,
    path: routesPath.LOBBY_BATTLE_PATH,
    component: Lobby
  },
  {
    name: routesName.FIND_TEAM,
    path: routesPath.FIND_TEAM_PATH,
    component: FindTeam
  },
  {
    name: routesName.RANKING,
    path: routesPath.RANKING,
    component: Ranking
  },
  {
    name: routesName.NORMAL,
    path: routesPath.NORMAL,
    component: PlayNormal,
    // TODO: WEB_PROD
    beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
      if (process.env.VUE_APP_BUILD === "DESKTOP-APP") {
        next();
      } else {
        next(constants.ROOT_PATH);
      }
    }
  },
  {
    name: routesName.HISTORY_BATTLE,
    path: routesPath.HISTORY_BATTLE_PATH,
    component: Lobby
  },
]