<template>
  <v-dialog v-model="showDialog" max-width="500" persistent>
    <div class="cl-dialog noselect">
      <img
        class="cl-bg"
        src="@/assets/images/dialog/bg_count_down.svg"
        alt=""
      />
      <div class="cl-title">Không đủ Gạo !!!</div>
      <img
        class="w-28 mb-6"
        src="@/assets/images/icons/GP_160x160.svg"
        alt=""
      />
      <div class="text-center text-customPurple cl-font-16-normal mb-2">
        Số Gạo hiện tại của bạn không đủ.
      </div>
      <div class="text-center text-customPurple cl-font-16-normal mb-2">
        Nhanh tay nạp thêm Gạo để mua <span class="text-customYellow2">[{{ nameItem }}]</span>
      </div>

      <div class="flex gap-5 absolute bottom-10">
        <v-btn class="cl-btn-accept" @click="onAccept()">
          <span class="cl-font-24-bold"> Nạp gạo </span>
        </v-btn>
        <v-btn class="cl-btn-cancel" @click="onCloseDialog()">
          <v-icon size="35">mdi-close</v-icon>
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { getToken } from "@/helpers/storage";

export default {
  name: "dialogBuyFailed",
  emit: ["closeDialog"],

  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
    nameItem: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      showDialog: this.value,
    };
  },

  watch: {
    showDialog(val, oldVal) {
      if (val === oldVal) return;
    },
    value(val, oldVal) {
      if (val === oldVal) return;
      this.showDialog = val;
    },
  },

  methods: {
    onCloseDialog() {
      this.$emit("closeDialog");
    },
    onAccept() {
      window.open(
        `${process.env.VUE_APP_ID_URL}?access_token=${getToken}`,
        "GBattle",
        "popup"
      );
      this.$emit("closeDialog");
    },
  },
};
</script>

<style lang="scss" scoped>
.cl-dialog {
  width: 500px;
  height: 450px;
  padding: 10px;
  .cl-title {
    position: absolute;
    top: 40px;
  }
  .cl-bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .cl-btn-accept {
    width: 250px;
    height: 60px;
  }
  .cl-btn-cancel {
    width: 60px;
    height: 60px;
    color: #7b72c6;
    border: 1px solid #7b72c6;
    background: transparent;
  }
}
</style>