import { BaseService } from "@/services/baseService";
import Apis from "@/modules/shop/services/apis"

export class ShopService extends BaseService {
  static async getItemsOntime(category: number) {
    try {
      const response = await this.request().get(Apis.GET_ITEMS_ONETIME.replace(":category", category.toString()))
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  static async getItems(category: number) {
    try {
      const response = await this.request().get(Apis.GET_ITEMS.replace(":category", category.toString()))
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  // eslint-disable-next-line
  static async transactionItems(payload: any) {
    try {
      const response = await this.request().post(Apis.TRANSACTION_ITEMS, payload)
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  // eslint-disable-next-line
  static async transactionShoMallItem(payload: any) {
    try {
      const response = await this.request().post(Apis.TRANSACTION_SHOP_MALL_ITEMS, payload)
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }

  // eslint-disable-next-line
  static async transactionOfferItem(payload: any) {
    try {
      const response = await this.request().post(Apis.TRANSACTION_OFFER_ITEMS, payload)
      return this.handleSuccess(response.data)
    } catch (error) {
      this.handleError(error)
    }
  }
}