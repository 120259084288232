<template>
  <div class="cl-items-player">
    <div v-for="(subCategory, index) in lstSubCategory" :key="index">
      <div
        class="h-9 bg-customBlack2 text-white cl-font-14-bold flex items-center pl-4 rounded-lg"
      >
        {{ subCategory.title }}
      </div>
      <div v-if="subCategory.lstItem.length" class="flex flex-wrap gap-5 py-5">
        <div
          v-for="(item, index) in subCategory.lstItem"
          :key="index"
          style="width: 180px"
          class="cl-item h-56 cursor-pointer flex flex-col items-center bg-customPurple4 pt-4 rounded-xxl relative"
          :class="
            item.UserItemStatus === userItemStatus.using
              ? 'border-2 border-customGreen3'
              : ''
          "
          @dblclick="usingItem(item, subCategory.lstItem)"
        >
          <v-avatar :size="150" :image="item.ImageUrl"></v-avatar>
          <v-icon
            v-if="item.UserItemStatus === userItemStatus.using"
            class="mt-3"
            :size="30"
            color="rgba(99, 194, 129, 1)"
          >
            mdi-check-circle
          </v-icon>
          <div
            v-if="item.Quantity > 1"
            class="text-customPurple w-5 h-5 rounded-full bg-customPurple2 flex items-center justify-center cl-font-10-bold absolute top-2 right-2 maxline1"
          >
            {{ item.Quantity }}
          </div>
          <div v-if="subCategory.showTitleItem" class="w-full relative">
            <div class="w-full px-2 text-white maxline1 cl-font-12-normal">
              {{ item.ItemTitle }}
            </div>
            <v-btn
              class="cl-btn-using mt-1 absolute right-2"
              height="30"
              variant="tonal"
              color="success"
              @click="usingItem(item, subCategory.lstItem)"
            >
              <span class="normal-case">Sử dụng</span>
            </v-btn>
          </div>
        </div>
      </div>
      <div v-else class="h-10 flex items-center justify-center text-white">
        Không có dữ liệu
      </div>
    </div>
    <ChangeInfo
      v-model="showDialogChangeInfo"
      :info="userInfo"
      @close-dialog="showDialogChangeInfo = false"
    />
  </div>
</template>

<script>
import { ref } from "vue";
import constants from "@/constants";
import { useStore } from "vuex";
import { key } from "@/store";
import { useI18n } from "vue-i18n";

// component
import ChangeInfo from "@/components/core/dialog/ChangeInfo.vue";

// services
import { ProfileService } from "@/modules/profile/services/profileService";

// storage
import { setUserInfo, getUserInfo } from "@/helpers/storage";

export default {
  name: "ItemsPlayerVue",

  props: {
    lstSubCategory: {
      typeof: Array,
      default: [],
    },
  },

  components: {
    ChangeInfo,
  },

  setup() {
    const { t } = useI18n();
    const translate = t;
    const store = useStore(key);
    const userInfo = ref(null);
    const showDialogChangeInfo = ref(false);
    const userItemStatus = ref(constants.USER_ITEM_STATUS);
    const subCategoryHandle = ref([
      {
        key: "avatar_url",
        subCategory: constants.SUB_CATEGORY.avatarGB,
        handleCategory: (item) => handleUsingAvatar(item),
      },
      {
        key: "frame_url",
        subCategory: constants.SUB_CATEGORY.frameGB,
        handleCategory: (item) => handleUsingFrame(item),
      },
      {
        key: "display_name",
        subCategory: constants.SUB_CATEGORY.nameChangeCard,
        handleCategory: (item) => handleUsingNameChangeCard(item),
      },
      {
        key: "",
        subCategory: constants.SUB_CATEGORY.boostCS2,
        handleCategory: (item) => handleUsingVoucher(item),
      },
    ]);

    async function usingItem(item, lstItem) {
      const subCategory = subCategoryHandle.value.find(
        (it) => it.subCategory == item.ItemSubcategoryId
      );
      if (subCategory) {
        try {
          await subCategory.handleCategory(item);
          switch (true) {
            case item.ItemSubcategoryId === constants.SUB_CATEGORY.avatarGB ||
              item.ItemSubcategoryId === constants.SUB_CATEGORY.frameGB:
              handleActiveItem(item, lstItem);
              handleSuccess();
              handleUpdateImageUrl(subCategory, item);
              break;

            case item.ItemSubcategoryId === constants.SUB_CATEGORY.boostCS2:
              handleSuccess();
              break;

            default:
              break;
          }
        } catch (error) {
          handleError(error);
        }
      }
    }

    async function handleUsingAvatar(item) {
      await ProfileService.changeAvatar(item.ItemId);
    }

    async function handleUsingFrame(item) {
      await ProfileService.changeFrame(item.ItemId);
    }

    async function handleUsingVoucher(item) {
      await ProfileService.usingVoucher(item.ItemId);
    }

    function handleActiveItem(item, lstItem) {
      const itemActive = lstItem.find(
        (it) => it.UserItemStatus === userItemStatus.value.using
      );
      if (itemActive) {
        itemActive.UserItemStatus = userItemStatus.value.owned;
      }
      item.UserItemStatus = userItemStatus.value.using;
    }

    async function handleUsingNameChangeCard() {
      showDialogChangeInfo.value = true;
      userInfo.value = JSON.parse(getUserInfo());
      userInfo.value.titleDialog = "Đổi tên";
      userInfo.value.title = "Tên hiển thị";
      userInfo.value.keyUpdate = "DisplayName";
      userInfo.value.keyVaule = "display_name";
      userInfo.value.update = async (payload) => {
        await ProfileService.updateProfile(payload);
      };
    }

    function handleUpdateImageUrl(subCategory, item) {
      const userInfo = JSON.parse(getUserInfo());
      userInfo[subCategory.key] = item.ImageUrl;
      setUserInfo(JSON.stringify(userInfo));
      store.dispatch("setUpdateInfoPlayer", true);
      setTimeout(() => {
        store.dispatch("setUpdateInfoPlayer", false);
      }, 200);
    }

    function handleSuccess() {
      store.dispatch("setSnackBar", {
        show: true,
        message: "Sử dụng vật phẩm thành công",
        type: constants.TYPE_SNACK_BAR.SUCCESS,
      });
    }

    function handleError(error) {
      store.dispatch("setSnackBar", {
        show: true,
        message: translate(`error_code[${error}]`),
        type: constants.TYPE_SNACK_BAR.ERROR,
      });
    }

    return {
      userInfo,
      userItemStatus,
      showDialogChangeInfo,
      usingItem,
    };
  },
};
</script>

<style lang="scss" scoped>
.cl-items-player {
  min-width: 980px;
  .cl-item {
    &:hover {
      .cl-btn-using {
        display: block;
      }
    }
    .cl-btn-using {
      display: none;
    }
  }
}
</style>