<template>
  <div class="noselect mx-7 my-5 overflow-hidden">
    <v-btn
      class="mr-2"
      icon
      size="24"
      slim
      variant="plain"
      @click="onClickBack"
    >
      <v-icon class="mt-n1 text-white/[0.2]">
        mdi-arrow-left-bold-circle
      </v-icon>
    </v-btn>
    <span class="text-white cl-font-24-bold">
      {{ $t("mission.rewardHistory") }}
    </span>
    <v-divider
      class="my-3 border-customPurple3 opacity-100"
      :thickness="1"
    ></v-divider>
    <div class="overflow-auto">
      <v-data-table
        id="id-virtual-reward"
        :headers="headers"
        :items="dataTable"
        :loading="loading"
        :height="getHeightTable()"
        no-filter
        disable-sort
        hide-default-footer
        density="comfortable"
        items-per-page="-1"
        loading-text="Đang tải dữ liệu"
        no-data-text="Không có dữ liệu"
        v-scroll.self="onScroll"
      >
        <template v-slot:[`item.stt`]="{ index }">
          <div>
            {{ index + 1 }}
          </div>
        </template>
        <template v-slot:[`item.item_title`]="{ item }">
          <div class="flex align-center">
            <img
              v-if="item.type === 1"
              class="w-12 object-cover"
              :src="item.image_url ?? item.ImageUrle"
              alt=""
            />
            <img
              v-else
              class="w-12 object-cover"
              src="@/assets/images/icons/g_point.svg"
              alt=""
            />
            <div class="text-customYellow2 ml-3 maxline2">
              {{ item.type === 1 ? item.item_title : `+${item.gpoint}` }}
            </div>
          </div>
        </template>
        <template v-slot:[`item.mission_name`]="{ item }">
          <div class="cl-font-14-normal maxline2">
            {{ item.mission_name }}
          </div>
        </template>
        <template v-slot:[`item.event_name`]="{ item }">
          <div class="cl-font-14-normal maxline2">
            {{ item.event_name }}
          </div>
        </template>
        <template v-slot:[`item.updated_time`]="{ item }">
          <div class="cl-font-14-normal">
            {{ getTimeReward(item.updated_time) }}
          </div>
        </template>
        <template v-slot:[`item.user_reward_status`]="{ item }">
          <span
            v-if="item.user_reward_status !== state.Missing"
            :class="missionState[item.user_reward_status].class"
          >
            {{ missionState[item.user_reward_status].text }}
          </span>
          <v-btn
            v-else
            variant="outlined"
            color="customYellow2"
            @click="onClickOpenDialog(item)"
          >
            <span class="normal-case cl-font-14-bold text-customYellow2">
              {{ $t("mission.reward_state.missing") }}
            </span>
          </v-btn>
        </template>
      </v-data-table>
    </div>
  </div>
  <DialogGiff
    v-model="isShowDialogGiff"
    :info="rewardContent"
    @closeDialog="onCloseDialog"
  />
  <DialogArtifacts
    v-model="isShowDialogItems"
    :info="rewardContent"
    :unit="'GP'"
    :keyMoney="'GPoint'"
    :keySaleMoney="'ItemGPoint'"
    @closeDialog="onCloseDialog"
    @acceptDialog="onAcceptDialogArtifacts"
  />
  <DialogInGame
    v-model="isShowDialogInGame"
    :info="rewardContent"
    :unit="'GP'"
    :keyMoney="'GPoint'"
    :keySaleMoney="'ItemGPoint'"
    @closeDialog="onCloseDialog"
    @acceptDialog="onAcceptDialogIngame"
  />
</template>

<script>
import constants from "@/constants";
import { ref } from "vue";
import { useStore } from "vuex";
import { key } from "@/store";
// router
import { useRouter } from "vue-router";
import routesPath from "@/routers/routesPath";
// service
import { MissionService } from "@/modules/missions/services/missionServices";
// i18n
import { useI18n } from "vue-i18n";
// storage
import { getUserInfo } from "@/helpers/storage";
// component
import DialogGiff from "@/components/core/dialog/reward/Gift.vue";
import DialogArtifacts from "@/components/core/dialog/shop/Artifacts.vue";
import DialogInGame from "@/components/core/dialog/shop/InGame.vue";

export default {
  components: {
    DialogGiff,
    DialogInGame,
    DialogArtifacts,
  },

  setup() {
    const headers = ref([
      {
        title: "TT",
        align: "center",
        sortable: false,
        key: "stt",
        width: "5%",
      },
      {
        title: "Loại quà",
        align: "start",
        sortable: false,
        key: "item_title",
        width: "25%",
      },
      {
        title: "Nhiệm vụ",
        align: "start",
        sortable: false,
        key: "mission_name",
        width: "15%",
      },
      {
        title: "Sự kiện",
        align: "start",
        sortable: false,
        key: "event_name",
        width: "15%",
      },
      {
        title: "Thời gian",
        align: "start",
        sortable: false,
        key: "updated_time",
        width: "15%",
      },
      {
        title: "Trạng thái",
        align: "center",
        sortable: false,
        key: "user_reward_status",
        width: "15%",
      },
    ]);
    const dataTable = ref([]);
    const params = ref({
      page_size: null,
      page_index: null,
    });
    const userInfo = JSON.parse(getUserInfo());
    const timeOutOnScroll = ref();
    const isLoadMore = ref(true);
    const loading = ref(false);
    const router = useRouter();
    const store = useStore(key);
    const state = constants.REWARD_STATUS;
    const { t } = useI18n();
    const isShowDialogGiff = ref(false);
    const isShowDialogItems = ref(false);
    const isShowDialogInGame = ref(false);
    const rewardContent = ref({});

    const missionState = ref({
      [state.Pending]: {
        text: t("mission.reward_state.pending"),
        class: "text-customPurple",
      },
      [state.Fail]: {
        text: t("mission.reward_state.fail"),
        class: "text-customOrange2",
      },
      [state.Success]: {
        text: t("mission.reward_state.success"),
        class: "text-customGreen",
      },
    });

    const rewardDialog = ref({
      [constants.CATEGORY.artifacts]: () => showDialogItems(),
      [constants.CATEGORY.shopMall]: () => showDialogItems(),
      [constants.CATEGORY.box]: () => showDialogInGame(),
    });

    function showDialogItems() {
      isShowDialogItems.value = true;
    }

    function showDialogInGame() {
      isShowDialogInGame.value = true;
    }

    async function onAcceptDialogArtifacts(data) {
      try {
        const payload = {
          UserRewardId: rewardContent.value.userRewardId,
          UserDisplayName: data.ReceiverName,
          PhoneNumber: data.ReceiverPhoneNumber ?? "",
          Address: data.ReceiverAddress ?? "",
        };
        await MissionService.upDateRewardInfo(payload);
        showNotify({
          message: "Gửi thông tin thành công",
          type: constants.TYPE_SNACK_BAR.SUCCESS,
        });
      } catch (error) {
        console.log(error);
      } finally {
        onCloseDialog();
      }
    }

    async function onAcceptDialogIngame(data) {
      try {
        const payload = {
          UserRewardId: rewardContent.value.userRewardId,
          Email: data.EmailLink,
          PersonalUrl: data.EmailLink,
          PhoneNumber: data.PhoneNumber,
        };
        await MissionService.upDateRewardInfo(payload);
        showNotify({
          message: "Gửi thông tin thành công",
          type: constants.TYPE_SNACK_BAR.SUCCESS,
        });
      } catch (error) {
        console.log(error);
      } finally {
        onCloseDialog();
      }
    }

    async function getListMissionRewardHistory(loadMore) {
      try {
        if (!loadMore) {
          initParamSearch();
          loading.value = true;
          dataTable.value = [];
        }
        if (!isLoadMore.value) return;
        const response = await MissionService.missionRewardHistory(
          params.value
        );

        isLoadMore.value = Boolean(response.Data && response.Data.length);

        if (loadMore) {
          dataTable.value = dataTable.value.concat(response.Data);
          return;
        }
        dataTable.value = response.Data;
      } catch (error) {
        isLoadMore.value = true;
        dataTable.value = [];
        console.log(error);
      } finally {
        loading.value = false;
      }
    }

    function getTimeReward(dateTime) {
      if (!dateTime) return;
      return new Date(dateTime * 1000)
        .toLocaleString("en-GB")
        .replace(/\//g, "-")
        .replace(",", " / ");
    }

    function getHeightTable() {
      if (process.env.VUE_APP_BUILD === "DESKTOP-APP") {
        return "calc(100vh - 185px)";
      }
      return "calc(100vh - 160px)";
    }

    function onClickBack() {
      router.push(routesPath.EVENTS_PATH);
    }

    function onClickOpenDialog(value) {
      if (value.user_reward_status !== state.Missing) return;
      rewardContent.value.userRewardId = value.user_reward_id;
      rewardContent.value.name = value.item_title;
      rewardContent.value.image = value.image_url;
      rewardContent.value.ItemSubcategoryId = parseInt(
        value.item_id.toString().substring(0, 4)
      );
      const itemId = value.item_id.toString().substring(0, 2);
      rewardDialog.value[itemId]();
    }

    function onCloseDialog() {
      isShowDialogGiff.value = false;
      isShowDialogItems.value = false;
      isShowDialogInGame.value = false;
      rewardContent.value = {};
    }

    function onScroll() {
      timeOutOnScroll.value && clearTimeout(timeOutOnScroll.value);
      timeOutOnScroll.value = setTimeout(() => {
        var item = document.getElementById("id-virtual-reward");
        if (
          Math.abs(item.scrollHeight - item.clientHeight - item.scrollTop) <
            450 &&
          isLoadMore.value
        ) {
          params.value.page_size === 5
            ? (params.value.page_index += 1)
            : (params.value.page_index = params.value.page_size / 5 + 1);
          params.value.page_size = 5;
          getListMissionRewardHistory(true);
        }
      }, 100);
    }

    function showNotify({ message, type }) {
      store.dispatch("setSnackBar", {
        show: true,
        message: message,
        type: type,
      });
    }

    function initParamSearch() {
      document.getElementById("id-virtual-reward").scrollTop = 0;
      params.value.page_index = 1;
      params.value.page_size = 20;
      isLoadMore.value = true;
    }

    function handleMissionReward(data) {
      let reward = dataTable.value.find(
        (it) => it.user_reward_id === data.user_reward_id
      );
      if (reward) {
        reward.user_reward_status = data.user_reward_status;
        reward.updated_time = data.updated_time;
      }
    }

    return {
      state,
      headers,
      dataTable,
      params,
      userInfo,
      timeOutOnScroll,
      isLoadMore,
      loading,
      isShowDialogGiff,
      isShowDialogItems,
      isShowDialogInGame,
      rewardContent,
      missionState,
      rewardDialog,
      showDialogItems,
      showDialogInGame,
      onAcceptDialogArtifacts,
      onAcceptDialogIngame,
      getListMissionRewardHistory,
      getTimeReward,
      getHeightTable,
      onClickBack,
      onClickOpenDialog,
      onCloseDialog,
      onScroll,
      showNotify,
      initParamSearch,
      handleMissionReward,
    };
  },
  mounted() {
    this.topicMqttReward = `${process.env.VUE_APP_TOPIC_RANKING_USER}${this.userInfo?.user_id}`;
    this.getListMissionRewardHistory();
    this.onSubscribeMqttReward();
    this.onListenMessageMqtt();
  },
  beforeUnmount() {
    this.onUnSubscribeMqttReward();
  },
  methods: {
    onListenMessageMqtt() {
      this.$clientMqtt.on("message", async (topic, message) => {
        if (topic !== this.topicMqttReward) {
          return;
        }
        const decoder = new TextDecoder("utf-8");
        let { Data } = JSON.parse(decoder.decode(message));
        this.handleMissionReward(Data);
      });
    },
    // MQTT
    onSubscribeMqttReward() {
      this.$clientMqtt.subscribe(this.topicMqttReward);
    },
    // MQTT
    onUnSubscribeMqttReward() {
      this.$clientMqtt.unsubscribe(this.topicMqttReward);
    },
  },
};
</script>

<style lang="scss" scoped>
#id-virtual-reward {
  overflow-y: auto !important;
}
.v-table {
  color: #fff !important;
  background: transparent !important;
}
::v-deep(.v-table__wrapper) {
  overflow: initial !important;
}
::v-deep(.v-data-table__tr:nth-child(even)) {
  background: rgba(255, 255, 255, 0.05);
}
::v-deep(.v-data-table__tr:nth-child(odd)) {
  background: rgba(0, 0, 0, 0.3);
}
::v-deep(.v-table__wrapper > table > thead > tr > th) {
  background: transparent;
}
::v-deep(.v-data-table__th) {
  background: white;
}
::v-deep(.v-data-table-header__content) {
  font-size: 14px;
  font-weight: 700;
}
::v-deep(.v-data-table__td) {
  border: 0px !important;
  font-size: 14px;
  font-weight: 700;
  height: 60px !important;
}
::v-deep(.v-data-table__tr:hover) {
  background: rgba(123, 114, 198, 0.2);
}
</style>
