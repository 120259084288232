<template>
  <div class="noselect flex justify-center mt-6">
    <div class="cl-info-player">
      <div
        class="h-9 bg-customBlack2 text-white cl-font-14-bold flex items-center pl-4 rounded-lg"
      >
        Thông tin cá nhân
      </div>
      <div
        v-for="(info, index) in infoPlayer"
        :key="index"
        class="mt-5 h-9 flex items-center px-4 justify-between"
      >
        <div class="flex">
          <div class="w-36 text-customGray cl-font-14-bold">
            {{ info.title }}
          </div>
          <div
            v-if="userInfo[info.keyVaule]"
            class="flex-1 maxline1 text-white cl-font-18-bold"
          >
            {{ userInfo[info.keyVaule] }}
          </div>
          <div
            v-else
            class="flex-1 text-customGray maxline1 italic cl-font-18-normal"
          >
            Chưa cập nhật
          </div>
        </div>
        <div
          v-if="info.isEdit"
          class="text-right text-customPurple cl-font-14-normal capitalize cursor-pointer"
          @click="onEditInfo(info)"
        >
          {{ userInfo[info.keyVaule] ? "Sửa" : "Thêm" }}
        </div>
      </div>
    </div>
    <ChangeInfo
      v-model="showDialog"
      :info="infoDialog"
      @close-dialog="showDialog = false"
    />
  </div>
</template>

<script>
import { ref } from "vue";

// component
import ChangeInfo from "@/components/core/dialog/ChangeInfo.vue";

// service
import { ProfileService } from "@/modules/profile/services/profileService";

export default {
  name: "InforPlayerVue",

  components: {
    ChangeInfo,
  },

  props: {
    userInfo: {
      type: Object,
      default: null,
    },
  },

  setup() {
    const showDialog = ref(false);
    const isBuyCard = ref(false);
    const infoDialog = ref(null);

    const infoPlayer = ref([
      {
        title: "Tên hiển thị",
        titleDialog: "Đổi tên",
        keyVaule: "display_name",
        keyUpdate: "DisplayName",
        rule: "regexName",
        update: (data) => ProfileService.updateProfile(data),
        isEdit: true,
        maxlength: 16,
      },
      {
        title: "Username",
        keyVaule: "username",
        isEdit: false,
      },
      {
        title: "Email",
        titleDialog: "Đổi Email",
        keyVaule: "email",
        keyUpdate: "Email",
        rule: "regexEmail",
        update: (data) => ProfileService.updateEmail(data),
        isEdit: false,
        maxlength: 200,
      },
      {
        title: "Điện thoại",
        titleDialog: "Đổi số điện thoại",
        keyVaule: "phone_number",
        keyUpdate: "PhoneNumber",
        rule: "regexPhone",
        update: (data) => ProfileService.updateProfile(data),
        isEdit: true,
        maxlength: 10,
      },
    ]);

    function onEditInfo(info) {
      showDialog.value = true;
      infoDialog.value = info;
      isBuyCard.value = false;
    }

    return {
      isBuyCard,
      showDialog,
      infoPlayer,
      infoDialog,
      onEditInfo,
    };
  },
};
</script>

<style lang="scss" scoped>
.cl-info-player {
  width: 980px;
}
</style>