<template>
  <div class="mx-7 mt-5 noselect">
    <span class="text-white cl-font-24-bold">{{ $t("mission.title") }}</span>
    <v-divider class="mt-5 mb-5" :thickness="1"></v-divider>
    <span class="text-white cl-font-16-normal">
      {{ $t("mission.description") }}
    </span>
    <div class="flex justify-between align-center">
      <div class="flex my-7 gap-5">
        <v-btn
          :color="
            timeState === eventTimeState.HappeningTime
              ? '#FF507A'
              : 'rgba(255, 255, 255, 0.2)'
          "
          @click="setTimeState(eventTimeState.HappeningTime)"
        >
          <span class="normal-case text-white">
            {{ $t("mission.happenning") }}
          </span>
        </v-btn>
        <v-btn
          :color="
            timeState === eventTimeState.OverTime
              ? '#FF507A'
              : 'rgba(255, 255, 255, 0.2)'
          "
          @click="setTimeState(eventTimeState.OverTime)"
        >
          <span class="normal-case text-white">{{
            $t("mission.finished")
          }}</span>
        </v-btn>
      </div>
      <v-btn class="background-btn-history" @click="onGoToRewardHistory()">
        <img src="@/assets/images/icons/reward.svg" alt="" srcset="" />
        <span class="normal-case text-white cl-font-14-bold ml-2">{{
          $t("mission.rewardHistory")
        }}</span>
      </v-btn>
    </div>

    <!-- TODO: danh sach mission -->
    <div
      class="flex gap-5 flex-wrap overflow-auto"
      :style="{ height: getHeightEvent() }"
    >
      <v-card
        v-for="(item, index) in lstEvent"
        :key="index"
        type="button"
        class="rounded-xl transition ease-in-out hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300"
        width="310"
        height="220"
        color="rgba(123, 114, 198, 0.08)"
        @click="onGoToDetail(item?.event_id)"
      >
        <v-img class="h-30" :src="item?.event_info?.event_background" cover>
        </v-img>
        <v-divider :thickness="1"></v-divider>
        <div class="flex justify-between mt-3 mx-4 items-center">
          <div class="text-customPurple cl-font-12-normal">
            {{ $t("mission.start_time") }}:
            <div>
              {{
                getTimeEvent(
                  item?.event_info?.start_time,
                  item?.event_info?.end_time
                )
              }}
            </div>
          </div>
          <div class="text-white flex">
            <span class="cl-font-12-bold mr-2">
              {{ $t("mission.progress") }} {{ item?.complete_mission }}/{{
                item?.total_mission
              }}
            </span>
            <img src="@/assets/images/icons/focus.svg" alt="" srcset="" />
          </div>
        </div>
        <div class="mx-4 mt-n1">
          <div class="mt-1 text-white cl-font-18-bold maxline1">
            {{ item?.event_info?.event_name }}
          </div>
          <div class="text-gray-500 cl-font-12-bold">
            {{ item?.event_info?.event_type_name }}
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import constants from "@/constants";
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";

// router
import routesNameMission from "@/modules/missions/routers/routesName";

// service
import { MissionService } from "@/modules/missions/services/missionServices";

export default {
  name: "missionsVue",

  setup() {
    const eventTimeState = constants.EVENT_TIME_STATE;
    const timeState = ref(eventTimeState.HappeningTime);
    const router = useRouter();
    const lstEvent = ref([]);

    async function getLstEvent() {
      try {
        const res = await MissionService.getListEvent(timeState.value);
        lstEvent.value = res.Data;
      } catch (error) {
        console.log(error);
      }
    }

    function setTimeState(state) {
      timeState.value = state;
      getLstEvent();
    }

    function getTimeEvent(startTime, endTime) {
      const start = new Date(startTime * 1000).toLocaleDateString("en-GB");
      const end = new Date(endTime * 1000).toLocaleDateString("en-GB");
      return `${start} - ${end}`;
    }

    function onGoToDetail(eventId) {
      router.push({
        name: routesNameMission.EVENT_DETAIL,
        params: {
          eventId: eventId ?? 0,
        },
      });
    }

    function getHeightEvent() {
      if (process.env.VUE_APP_BUILD === "DESKTOP-APP") {
        return "calc(100vh - 348px)";
      }
      return "calc(100vh - 323px)";
    }

    function onGoToRewardHistory() {
      router.push({
        name: routesNameMission.REWARD_HISTORY,
      });
    }

    onMounted(() => {
      getLstEvent();
    });

    return {
      lstEvent,
      timeState,
      eventTimeState,
      setTimeState,
      getTimeEvent,
      getHeightEvent,
      onGoToDetail,
      onGoToRewardHistory,
    };
  },
};
</script>

<style lang="scss" scoped>
.background-btn-history {
  background-color: transparent;
  border: 1px solid #7b72c6;
}
</style>
