<template>
  <v-dialog v-model="internalValue" max-width="450" min-width="450" persistent>
    <div class="cl-dialog">
      <img
        class="cl-bg"
        src="@/assets/images/dialog/bg_count_down.svg"
        alt=""
      />
      <div class="cl-title mt-5">{{ info.titleDialog }}</div>
      <div v-if="isBuyCard" class="flex flex-col items-center">
        <div class="text-white cl-font-14-bold">
          Bạn cần mua <span class="text-customYellow2">[Thẻ đổi tên] </span> để
          thay đổi tên hiển thị
        </div>
        <img src="@/assets/images/icons/tdt.svg" alt="" />
      </div>
      <v-form ref="formModel" v-else class="w-85 mt-n6">
        <div class="text-white cl-font-14-bold">{{ info.title }}</div>
        <v-text-field
          v-model="modelText"
          flat
          dirty
          autofocus
          color="#502F9F"
          density="compact"
          variant="outlined"
          base-color="#502F9F"
          hide-spin-buttons
          :maxlength="info.maxlength"
          :rules="info.rule ? [rules[info.rule]] : []"
        >
        </v-text-field>
      </v-form>
      <v-btn
        width="24"
        height="24"
        class="absolute right-2 top-5"
        icon
        variant="plain"
        color="#ffffff"
        @click="onCloseDialog()"
      >
        <v-icon size="24">mdi-close</v-icon>
      </v-btn>
      <v-btn class="cl-btn-accept" @click="onAccept()">
        {{ isBuyCard ? "Mua" : "Lưu" }}
      </v-btn>
    </div>
  </v-dialog>
</template>

<script>
import constants from "@/constants";

// storage
import { setUserInfo, getUserInfo } from "@/helpers/storage";

export default {
  name: "dialogReport",
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
    isBuyCard: {
      type: Boolean,
      required: true,
      default: false,
    },
    info: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      regexName: /^(?!.*\s)[A-Za-z0-9]{4,16}$/,
      regexPhone: /^[0-9.,()+]+$/,
      regexEmail:
        "^[A-Za-z0-9-\\+\\_]+(\\.[A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$",
      modelText: null,
      internalValue: this.value,
      rules: {
        regexName: () => {
          const regex = this.regexName;
          if (!this.modelText || !this.modelText.length) {
            return "Tên hiển thị không được để trống";
          }
          if (this.modelText && !regex.test(this.modelText.trim())) {
            return "Độ dài từ 4 đến 16 ký tự, không dùng tiếng Việt có dấu, không ký tự đặc biệt và chứa khoảng trắng đầu cuối.";
          }
          return true;
        },
        regexPhone: () => {
          const regex = this.regexPhone;
          if (!this.modelText || !this.modelText.length) {
            return "Số điện thoại không được để trống";
          }
          if (this.modelText && !regex.test(this.modelText.trim())) {
            return "Số điện thoại không hợp lệ";
          }
          return true;
        },
        regexEmail: () => {
          var regex = new RegExp(this.regexEmail);
          if (!this.modelText || !this.modelText.length) {
            return "Email không được để trống";
          }
          if (this.modelText && !regex.exec(this.modelText.trim())) {
            return "Email không đúng định dạng";
          }
          return true;
        },
      },
    };
  },
  watch: {
    internalValue(val, oldVal) {
      if (val === oldVal) return;
    },
    value(val, oldVal) {
      if (val === oldVal) return;
      this.internalValue = val;
    },
  },
  methods: {
    async onAccept() {
      try {
        const { valid } = await this.$refs.formModel.validate();
        if (valid) {
          // call api
          const payload = {};
          payload[this.info.keyUpdate] = this.modelText;
          await this.info.update(payload);

          // noti success
          this.$store.dispatch("setSnackBar", {
            show: true,
            message: "Cập nhật thông tin thành công",
            type: constants.TYPE_SNACK_BAR.SUCCESS,
          });

          // update info
          this.updateUserInfo();
        }
      } catch (error) {
        this.$store.dispatch("setSnackBar", {
          show: true,
          message: this.$t(`error_code[${error}]`),
          type: constants.TYPE_SNACK_BAR.ERROR,
        });
      } finally {
        this.modelText = null;
      }
    },

    updateUserInfo() {
      const userInfo = JSON.parse(getUserInfo());
      userInfo[this.info.keyVaule] = this.modelText;
      setUserInfo(JSON.stringify(userInfo));
      this.$store.dispatch("setUpdateInfoPlayer", true);
      setTimeout(() => {
        this.$store.dispatch("setUpdateInfoPlayer", false);
        this.onCloseDialog();
      }, 200);
    },

    onCloseDialog() {
      this.modelText = null;
      this.$emit("closeDialog");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.v-text-field input) {
  color: #ffffff !important;
  border-radius: 3px !important;
}

.cl-dialog {
  width: 450px;
  height: 275px;
  padding: 10px;
  justify-content: space-between;
  .cl-bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .cl-btn-accept {
    width: 180px;
    height: 40px;
    font-size: 18px;
  }
}
</style>