/* eslint-disable */
import { defineComponent } from 'vue';

// constants
import constants from "@/constants";

// service
import { BattleService } from "@/modules/battleCS2/services/battleServices";

// storage
import { setInfoParty, removeInfoParty, getInfoParty, getAntiCheat } from "@/helpers/storage";

// payload
import joinPartyPayload from "@/modules/battleCS2/types/party";
import createPartyPayload from "@/modules/battleCS2/types/party";

// router
import routerPathCs2 from "@/modules/battleCS2/routers/routesPath";
import routesNameCs2 from "@/modules/battleCS2/routers/routesName";
import routesPath from "@/routers/routesPath";

// payload
import joinLobbyPayload from "@/modules/battleCS2/types/lobby";

interface CountDown {
  show: boolean;
  gameId: string;
  numberAccept: number;
  startTime: number;
}

export default defineComponent({
  data(): any {
    return {
      partyId: "",
      gameName: "cs2",
      infoParty: null,
      dialogCountDown: null,
      listMember: [{}, {}, {}, {}, {}],
      partyState: [
        {
          state: constants.PARTY_STATE.waiting,
          handleState: () => this.showSnackbarFindMatch(),
        },
        {
          state: constants.PARTY_STATE.found,
          handleState: (val: any) => this.handleStateLobbyFound(val),
        },
        {
          state: constants.PARTY_STATE.gamingBan,
          handleState: (val: any) => this.handleStateGaming(val),
        },
        {
          state: constants.PARTY_STATE.gaming,
          handleState: (val: any) => this.handleStateGaming(val),
        },
        {
          state: constants.PARTY_STATE.reject,
          handleState: () => this.handleStateRemoveParty(),
        },
        {
          state: constants.PARTY_STATE.timeout,
          handleState: () => this.handleStateRemoveParty(),
        },
      ],
      actionMqtt: [
        {
          action: "making",
          handleAction: () => this.showSnackbarFindMatch(),
        },
        {
          action: "cancel_making",
          handleAction: () => this.hideSnackbarFindMatch(),
        },
        {
          action: "found_game",
          handleAction: (data: any) => {
            this.hideSnackbarFindMatch();
            this.showDialogCountDown({
              show: true,
              gameId: data.game_id,
              numberAccept: 0,
              startTime: 60
            } as CountDown);
          },
        },
        {
          action: "cancel_game",
          handleAction: () => this.hideDialogCountDown(),
        },
      ],
    }
  },

  methods: {
    async getInfoParty() {
      try {
        const res = await BattleService.getInfoParty({
          party_id: ""
        } as joinPartyPayload);
        setInfoParty(JSON.stringify(res.Data));
        this.infoParty = res.Data
        return true
      } catch (error) {
        removeInfoParty();
        if (this.$route.fullPath === routerPathCs2.ROOM_BATTLE_PATH) {
          this.$router.push(routesPath.G_BATTLE_CS2_PATH)
        }
        return false
      } finally {
        this.$store.dispatch("setUpdateInfoParty", true);
      }
    },

    checkAntiCheat() {
      const antiCheat = getAntiCheat();
      if (antiCheat !== "ON") {
        this.$store.dispatch("setSnackBar", {
          show: true,
          message: "Bạn chưa bật anti cheat. vui lòng liên hệ CSKH để được hỗ trợ",
          type: constants.TYPE_SNACK_BAR.ERROR,
        });
        return false
      }
      return true
    },

    async createParty() {
      // try {
      //   if (!this.infoParty) {
      //     const payload = {
      //       game_name: this.gameName,
      //       party_mode: constants.PARTY_MODE.RANKING,
      //     } as createPartyPayload
      //     await BattleService.createParty(payload);
      //   }
      //   this.$router.push({
      //     name: routesNameCs2.ROOM_BATTLE,
      //   });
      // } catch (error) {
      //   if (error == 500003) {
      //     this.getConfigRanking();
      //     return
      //   }
      //   this.$store.dispatch("setSnackBar", {
      //     show: true,
      //     message: this.$t(`error_code[${error}]`),
      //     type: constants.TYPE_SNACK_BAR.ERROR,
      //   });
      // }
      // TODO: WEB_PROD
      if (process.env.VUE_APP_BUILD == "DESKTOP-APP") {
        if (!this.checkAntiCheat()) {
          return
        }
        try {
          if (!this.infoParty) {
            const payload = {
              game_name: this.gameName,
              party_mode: constants.PARTY_MODE.RANKING,
            } as createPartyPayload
            await BattleService.createParty(payload);
          }
          this.$router.push({
            name: routesNameCs2.ROOM_BATTLE,
          });
        } catch (error) {
          if (error == 500003) {
            this.getConfigRanking();
            return
          }
          this.$store.dispatch("setSnackBar", {
            show: true,
            message: this.$t(`error_code[${error}]`),
            type: constants.TYPE_SNACK_BAR.ERROR,
          });
        }
      } else {
        this.downloadGplay();
      }
    },

    downloadGplay() {
      window.open(process.env.VUE_APP_DOWNLOAD_GPLAY, "_self");
      this.$store.dispatch("setSnackBar", {
        show: true,
        message: "Đang tải bộ cài GPlay vui lòng đợi trong giây lát",
        type: constants.TYPE_SNACK_BAR.SUCCESS,
      });
    },

    async getConfigRanking() {
      try {
        const res = await BattleService.getConfigRanking(this.gameName);
        this.$store.dispatch("setSnackBar", {
          show: true,
          message: res.Data.rule ?? this.$t(`error_code.500003`),
          type: constants.TYPE_SNACK_BAR.ERROR,
        });
      } catch (error) {
        this.$store.dispatch("setSnackBar", {
          show: true,
          message: this.$t(`error_code.500003`),
          type: constants.TYPE_SNACK_BAR.ERROR,
        });
      }
    },

    handleUpdateInfoParty() {
      if (process.env.VUE_APP_BUILD == "DESKTOP-APP") {
        const infoParty = JSON.parse(getInfoParty()!);
        if (this.partyId !== infoParty?.party_id) {
          if (this.partyId) {
            this.onUnSubscribeMqttRoom();
          }
          this.partyId = infoParty?.party_id;
          if (this.partyId) {
            this.onSubscribeMqttRoom();
            this.onListenMessageMqtt();
          }
        }
        if (infoParty) {
          const partyState = this.partyState.find(
            (it: any) => it.state === infoParty.state
          );
          if (partyState) {
            partyState.handleState(infoParty);
          }
        }
      }
    },

    handleStateLobbyFound(data: any) {
      this.hideSnackbarFindMatch();
      this.showDialogCountDown({
        show: true,
        gameId: data.game_id_current,
        numberAccept: data.player_game_current_accept,
        startTime: data.found_game_countdown
      } as CountDown);
      if (data.player_game_current_accept === 10) {
        this.onJoinLobby(data)
      }
    },

    async onJoinLobby(data: any) {
      try {
        const payload = {
          anti_hack: true,
          ping: 99,
          lobby_id: data?.lobby_id,
          game_code: "cs2",
          party_id: data?.party_id,
        } as joinLobbyPayload;
        await BattleService.joinLobby("cs2", payload);
      } catch (error) {
        console.log(error);
      }
    },

    handleStateGaming(data: any) {
      this.$router.push({
        name: routesNameCs2.LOBBY_BATTLE,
        params: {
          lobbyId: data.lobby_id,
        },
      });
    },

    handleStateRemoveParty() {
      removeInfoParty()
      if (this.$route.fullPath === routerPathCs2.ROOM_BATTLE_PATH) {
        this.$router.push(routesPath.G_BATTLE_CS2_PATH)
      }
    },

    showSnackbarFindMatch() {
      this.$store.dispatch("setSnackBarFindMatch", true);
    },

    hideSnackbarFindMatch() {
      this.$store.dispatch("setSnackBarFindMatch", false);
    },

    showDialogCountDown(data: CountDown) {
      this.dialogCountDown = data;
      this.$store.dispatch("setDialogCountDown", JSON.parse(JSON.stringify(data)));
    },

    hideDialogCountDown() {
      const data = {
        show: false,
        gameId: this.dialogCountDown?.game_id_current,
        numberAccept: this.dialogCountDown?.player_game_current_accept,
        startTime: this.dialogCountDown?.found_game_countdown
      }
      this.$store.dispatch("setDialogCountDown", JSON.parse(JSON.stringify(data)));
    },

    // MQTT
    onUnSubscribeMqttRoom() {
      const topicParty = `${process.env.VUE_APP_TOPIC_PARTY}${this.partyId}`;
      this.$clientMqtt.unsubscribe(topicParty);
    },

    // MQTT
    onSubscribeMqttRoom() {
      const topicParty = `${process.env.VUE_APP_TOPIC_PARTY}${this.partyId}`;
      this.$clientMqtt.subscribe(topicParty);
    },

    // MQTT
    onListenMessageMqtt() {
      const topicParty = `${process.env.VUE_APP_TOPIC_PARTY}${this.partyId}`;
      this.$clientMqtt.on("message", async (topic: any, message: any) => {
        if (topicParty != topic) {
          return;
        }
        const decoder = new TextDecoder("utf-8");
        const { Action, Data } = JSON.parse(decoder.decode(message));
        const actionMqtt = this.actionMqtt.find((it: any) => it.action === Action);
        if (actionMqtt) {
          actionMqtt.handleAction(Data);
        }
      });
    },
  },
})