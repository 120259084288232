<template>
  <v-data-table
    class="mt-4"
    id="id-virtual-history"
    :headers="headers"
    :items="dataTable"
    :loading="loading"
    :height="getHeightTable()"
    items-per-page="-1"
    loading-text="Đang tải dữ liệu"
    no-data-text="Không có dữ liệu"
    no-filter
    disable-sort
    color="transparent"
    density="comfortable"
    item-value="TransactionPaymentId"
    hide-default-footer
    v-scroll.self="onScroll"
  >
    <template v-slot:[`item.index`]="{ index }">
      <div class="text-white cl-font-16-bold">{{ index + 1 }}</div>
    </template>
    <template v-slot:[`item.Title`]="{ item }">
      <div class="text-customYellow2 cl-font-14-bold">{{ item.Title }}</div>
    </template>
    <template v-slot:[`item.CoinExpEventKind`]="{ item }">
      <div>{{ constants.TRANSACTION_TYPE[item.CoinExpEventKind] }}</div>
    </template>
    <template v-slot:[`item.CreatedAt`]="{ item }">
      <div>{{ formattedDate(item.CreatedAt) }}</div>
    </template>
  </v-data-table>
</template>

<script>
import constants from "@/constants";

// service
import { ProfileService } from "@/modules/profile/services/profileService";

export default {
  name: "HistoryTransaction",

  data: () => ({
    constants,
    pageIndex: 1,
    pageSize: 20,
    dataTable: [],
    loading: false,
    isLoadMore: true,
    timeOutOnScroll: null,
    headers: [
      {
        title: "TT",
        align: "center",
        sortable: false,
        key: "index",
      },
      { title: "Sản phẩm", key: "Title", sortable: false, align: "start" },
      { title: "Gạo", key: "CoinChange", sortable: false, align: "start" },
      { title: "GPoint", key: "GPointChange", sortable: false, align: "start" },
      {
        title: "Loại giao dịch",
        key: "CoinExpEventKind",
        sortable: false,
        align: "start",
      },
      {
        title: "Mã giao dịch",
        key: "TransactionPaymentId",
        sortable: false,
        align: "start",
      },
      { title: "Thời gian", key: "CreatedAt", sortable: false, align: "start" },
    ],
  }),

  mounted() {
    this.pageIndex = 1;
    this.getLstTransaction();
  },

  methods: {
    getHeightTable() {
      return process.env.VUE_APP_BUILD == "DESKTOP-APP"
        ? "calc(100vh - 355px)"
        : "calc(100vh - 355px)";
    },
    async getLstTransaction() {
      try {
        this.loading = true;
        const params = {
          pageSize: this.pageSize,
          pageIndex: this.pageIndex,
        };
        const res = await ProfileService.historyTransaction(params);
        this.dataTable = res.Data ?? [];
      } catch (error) {
        console.log(error);
      } finally {
        setTimeout(() => {
          this.loading = false;
        }, 300);
      }
    },
    async handleScroll() {
      try {
        const params = {
          pageSize: this.pageSize,
          pageIndex: this.pageIndex,
        };
        const res = await ProfileService.historyTransaction(params);
        this.dataTable = this.dataTable.concat(res.Data ?? []);
        this.isLoadMore = Boolean(res.Data && res.Data.length);
      } catch (error) {
        console.log(error);
      }
    },
    onScroll() {
      this.timeOutOnScroll && clearTimeout(this.timeOutOnScroll);
      this.timeOutOnScroll = setTimeout(() => {
        var item = document.getElementById("id-virtual-history");
        if (
          Math.abs(item.scrollHeight - item.clientHeight - item.scrollTop) <
            450 &&
          this.isLoadMore
        ) {
          this.pageIndex += 1;
          this.handleScroll();
        }
      }, 100);
    },
    formattedDate(timestamp) {
      const date = new Date(timestamp);

      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Tháng bắt đầu từ 0
      const year = date.getFullYear();

      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");

      return `${day}-${month}-${year} / ${hours}:${minutes}:${seconds}`;
    },
  },
};
</script>

<style lang="scss" scoped>
#id-virtual-history {
  overflow-y: auto !important;
}
.v-table {
  color: #fff !important;
  background: transparent !important;
  ::v-deep(hr) {
    display: none;
  }
}
::v-deep(.v-table__wrapper) {
  overflow: initial !important;
}
::v-deep(.v-data-table__td) {
  border: 0px !important;
}
::v-deep(.v-data-table__tr:nth-child(even)) {
  background: rgba(255, 255, 255, 0.05);
}
::v-deep(.v-data-table__tr:nth-child(odd)) {
  background: rgba(0, 0, 0, 0.3);
}
::v-deep(
    .v-table.v-table--fixed-header > .v-table__wrapper > table > thead > tr > th
  ) {
  background: transparent;
}
::v-deep(.v-data-table__th) {
  background: transparent;
}
::v-deep(.v-data-table-header__content) {
  font-size: 14px;
  font-weight: 700;
}
::v-deep(.v-data-table__td) {
  font-size: 14px;
  font-weight: 400;
}
::v-deep(.v-data-table__tr:hover) {
  background: rgba(123, 114, 198, 0.2);
}
</style>