<template>
  <div class="image-container">
    <v-img
      class="rounded-xxl cursor-pointer"
      :width="width"
      :height="height"
      :min-width="minWidth"
      :min-height="minHeight"
      :src="imageSrc"
      :alt="alt"
      @click="onClickImage"
    >
      <template v-slot:placeholder>
        <div class="flex align-center justify-center fill-height">
          <v-progress-circular
            size="40"
            color="rgba(255, 80, 122, 1)"
            indeterminate
          ></v-progress-circular>
        </div>
      </template>
    </v-img>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      required: false,
    },
    height: {
      type: Number,
      required: false,
    },
    minWidth: {
      type: Number,
      required: false,
    },
    minHeight: {
      type: Number,
      required: false,
    },
    src: {
      type: String,
      required: true,
    },
    targetUrl: {
      type: String,
      required: false,
    },
    alt: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      imageSrc: ""
    }
  },
  
  mounted() {
    setTimeout(() => {
      this.imageSrc = this.src  
    }, 200);
  },

  methods: {
    onClickImage() {
      if (this.targetUrl) {
        if (process.env.VUE_APP_BUILD == "DESKTOP-APP") {
          // eslint-disable-next-line
          const { shell } = require("electron");
          shell.openExternal(this.targetUrl);
          return;
        }
        window.open(this.targetUrl, "_blank");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.image-container {
  position: relative;
  width: 100%;
  height: 100%;
}

img {
  display: block;
  width: 100%;
  height: auto;
}
</style>
