import constants from '@/constants'

export default {
  G_BATTLE_CS2_PATH: `${constants.ROOT_PATH}battle/cs2`,
  TOURNAMENT_PATH: `${constants.ROOT_PATH}tournaments`,
  LIVE_PATH: `${constants.ROOT_PATH}lives`,
  PROFILE_PATH: `${constants.ROOT_PATH}profile/:userId`,
  EVENTS_PATH: `${constants.ROOT_PATH}events`,
  G_BATTLE_VIP_PATH: `${constants.ROOT_PATH}gbattle/vip`,
  SHOP_PATH: `${constants.ROOT_PATH}gbattle/shop`,
  EXCHANGE_GIFT_PATH: `${constants.ROOT_PATH}gbattle/exchange/gift`
}