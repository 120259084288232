<template>
  <div class="cl-inventory noselect flex h-max mx-20 my-3">
    <div class="mt-1 w-52 mr-5">
      <div class="text-white mb-2 cl-font-20-bold flex">
        <img
          class="mr-1"
          src="@/assets/images/icons/shop.svg"
          alt=""
          srcset=""
        />
        Phần thưởng
      </div>
      <v-list lines="two">
        <v-list-item
          v-for="(item, index) in lstItem"
          :key="index"
          class="hover:bg-customPurple4"
          @click="onHandleActiveItem(item)"
        >
          <template v-slot:title>
            <div class="w-max flex cl-font-16-bold uppercase">
              <div class="w-2 mr-3" style="margin-top: -2px">
                <v-icon
                  v-show="itemActive?.id === item.id"
                  color="#ff507a"
                  size="22"
                >
                  mdi-chevron-right
                </v-icon>
              </div>
              <div
                :class="
                  itemActive?.id === item.id
                    ? 'text-white'
                    : 'text-customPurple'
                "
              >
                {{ item.title }}
              </div>
            </div>
          </template>
        </v-list-item>
      </v-list>
      <div class="text-white cl-font-14-normal cl-warning">
        <v-icon class="mb-1" color="#E6BA20">mdi-flash-triangle-outline</v-icon>
        <div>
          Số lượng quà tặng có giới hạn, hãy đổi quà sớm để nhận ngay phần
          thưởng cho mình.
        </div>
      </div>
    </div>

    <div class="flex-1 cl-components">
      <Transition mode="out-in" name="slide-fade">
        <component
          :is="itemActive?.component"
          :unit="'GP'"
          :keyMoney="'GPoint'"
          :keySaleMoney="'ItemGPoint'"
          :data="itemActive?.data"
          :lstSubCategory="itemActive?.lstSubCategory"
          @buyItem="onBuyItem"
        />
      </Transition>
    </div>

    <DialogArtifacts
      v-if="isShowDialogArtifacts"
      v-model="isShowDialogArtifacts"
      :info="itemBuy"
      :unit="'GP'"
      :keyMoney="'GPoint'"
      :keySaleMoney="'ItemGPoint'"
      @closeDialog="onCloseDialogArtifact"
      @acceptDialog="onAcceptDialogArtifact"
    />

    <DialogInGame
      v-if="isShowDialogInGame"
      v-model="isShowDialogInGame"
      :info="itemBuy"
      :unit="'GP'"
      :keyMoney="'GPoint'"
      :keySaleMoney="'ItemGPoint'"
      @closeDialog="onCloseDialogInGame"
      @acceptDialog="onAcceptDialogInGame"
    />
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import constants from "@/constants";
import { useStore } from "vuex";
import { key } from "@/store";
import { useI18n } from "vue-i18n";

// components
import Items from "../components/Items.vue";
import DialogArtifacts from "@/components/core/dialog/shop/Artifacts.vue";
import DialogInGame from "@/components/core/dialog/shop/InGame.vue";

// services
import { ShopService } from "@/modules/shop/services/shopServices";

export default {
  name: "ExchangeGifts",

  components: {
    Items,
    DialogInGame,
    DialogArtifacts,
  },

  setup() {
    // store
    const store = useStore(key);
    const { t } = useI18n();

    // data
    const itemBuy = ref(null);
    const itemActive = ref(null);
    const buySuccess = ref(false);
    const isShowDialogInGame = ref(false);
    const isShowDialogArtifacts = ref(false);
    const lstItem = ref([
      {
        id: constants.EXCHANGE_GIFFS.inGame,
        title: "quà in-game",
        data: [],
        lstSubCategory: [
          {
            title: "In-game",
            lstItem: [],
            category: constants.CATEGORY.box,
            subCategory: [constants.SUB_CATEGORY.box],
          },
          {
            title: "Voucher",
            lstItem: [],
            category: constants.CATEGORY.box,
            subCategory: [constants.SUB_CATEGORY.voucher],
          },
        ],
        getData: () => getItemsSubCategory(),
        component: Items,
      },
      {
        id: constants.EXCHANGE_GIFFS.artifacts,
        title: "quà hiện vật",
        data: [],
        lstSubCategory: [
          {
            title: "Hiện vật",
            lstItem: [],
            category: constants.CATEGORY.box,
            subCategory: [constants.SUB_CATEGORY.shopMallGb],
          },
        ],
        getData: () => getItemsSubCategory(),
        component: Items,
      },
    ]);

    const actionMqtt = [
      {
        actions: "UpdateItemQuantity",
        handleAction: (data) => handleUpdateItemQuantity(data),
      },
    ];

    function handleUpdateItemQuantity(data) {
      itemActive.value.lstSubCategory.every((it) => {
        const item = it.lstItem.find((val) => val.ItemId === data.ItemId);
        if (item) {
          item.ReceivedQuantity = data.ReceivedQuantity;
          item.TotalQuantity = data.TotalQuantity;
          return false;
        }
        return true;
      });
    }

    function onHandleActiveItem(item) {
      itemActive.value = item;
      itemActive.value.getData();
    }

    async function getItemsSubCategory() {
      try {
        itemActive.value.lstSubCategory.forEach(async (item) => {
          const res = await ShopService.getItems(item.category);
          item.lstItem = [];
          item.loading = false;
          item.subCategory.forEach((sub) => {
            item.lstItem = item.lstItem.concat(
              res.Data[0].ListItems.filter(
                (val) => val.ItemSubcategoryId === sub
              )
            );
          });
        });
      } catch (error) {
        console.log(error);
      }
    }

    function onBuyItem(item) {
      itemBuy.value = item;
      switch (itemActive.value.id) {
        case constants.EXCHANGE_GIFFS.inGame:
          isShowDialogInGame.value = true;
          break;

        case constants.EXCHANGE_GIFFS.artifacts:
          isShowDialogArtifacts.value = true;
          break;
        default:
          break;
      }
    }

    // dialog Artifact
    function onCloseDialogArtifact() {
      isShowDialogArtifacts.value = false;
    }

    async function onAcceptDialogArtifact(delivery) {
      try {
        isShowDialogArtifacts.value = false;
        if (
          !delivery.ReceiverName ||
          !delivery.ReceiverAddress ||
          !delivery.ReceiverPhoneNumber
        ) {
          return;
        }
        delivery.Platform = 1;
        const payload = {
          OrderItem: {
            ItemId: itemBuy.value.ItemId,
            Quantity: 1,
            CurrencyType: constants.CURRENCY_TYPE.gpoint,
            Platform: 1,
          },
          Delivery: delivery,
        };
        await ShopService.transactionShoMallItem(payload);
        store.dispatch("setSnackBar", {
          show: true,
          message: "Gửi thông tin thành công",
          type: constants.TYPE_SNACK_BAR.SUCCESS,
        });
      } catch (error) {
        store.dispatch("setSnackBar", {
          show: true,
          message: t(`error_code[${error}]`),
          type: constants.TYPE_SNACK_BAR.ERROR,
        });
      }
    }

    // dialog in game
    function onCloseDialogInGame() {
      isShowDialogInGame.value = false;
    }

    async function onAcceptDialogInGame(tradeOffer) {
      try {
        isShowDialogInGame.value = false;
        if (!tradeOffer.EmailLink) return;
        let payload = {
          OrderItem: {
            ItemId: itemBuy.value.ItemId,
            Quantity: 1,
            CurrencyType: constants.CURRENCY_TYPE.gpoint,
            Platform: 1,
          },
          TradeOffer: {},
        };
        if (itemBuy.value.ItemSubcategoryId === constants.SUB_CATEGORY.box) {
          payload.TradeOffer.TradeOfferLink = tradeOffer.EmailLink;
        } else {
          payload.TradeOffer = tradeOffer;
        }
        await ShopService.transactionOfferItem(payload);
        store.dispatch("setSnackBar", {
          show: true,
          message: "Gửi thông tin thành công",
          type: constants.TYPE_SNACK_BAR.SUCCESS,
        });
      } catch (error) {
        store.dispatch("setSnackBar", {
          show: true,
          message: t(`error_code[${error}]`),
          type: constants.TYPE_SNACK_BAR.ERROR,
        });
      }
    }

    onMounted(() => {
      itemActive.value = lstItem.value[0];
      itemActive.value.getData();
    });

    return {
      lstItem,
      itemBuy,
      itemActive,
      buySuccess,
      actionMqtt,
      isShowDialogInGame,
      isShowDialogArtifacts,
      onBuyItem,
      onHandleActiveItem,
      onCloseDialogInGame,
      onAcceptDialogInGame,
      onCloseDialogArtifact,
      onAcceptDialogArtifact,
    };
  },

  beforeUnmount() {
    const topicShopAll = `${process.env.VUE_APP_TOPIC_ALL_SHOP}`;
    this.$clientMqtt.subscribe(topicShopAll);
  },

  created() {
    this.onSubscribeMqtt();
    this.onListenMessageMqtt();
  },

  methods: {
    onSubscribeMqtt() {
      const topicShopAll = `${process.env.VUE_APP_TOPIC_ALL_SHOP}`;
      this.$clientMqtt.subscribe(topicShopAll);
    },

    onListenMessageMqtt() {
      const topicShopAll = `${process.env.VUE_APP_TOPIC_ALL_SHOP}`;

      this.$clientMqtt.on("message", async (topic, message) => {
        if (topic !== topicShopAll) {
          return;
        }
        const decoder = new TextDecoder("utf-8");
        let { Action, Data } = JSON.parse(decoder.decode(message));
        const action = this.actionMqtt.find((it) => it.actions === Action);
        if (action) {
          action.handleAction(Data ?? JSON.parse(decoder.decode(message)));
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.cl-warning {
  padding: 12px;
  margin-top: 20px;
  border-radius: 10px;
  background: #0000004d;
  border: 1px solid #7b72c633;
}
.cl-inventory {
  height: calc(100vh - 80px);
  overflow: hidden;
  .cl-components {
    min-width: 990px;
    padding-right: 5px;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
  }
  .v-list {
    width: 208px;
    min-width: max-content;
    height: max-content;
    color: #fff;
    border-radius: 10px;
    border: none !important;
    margin-top: 0px !important;
    background: rgba(123, 114, 198, 0.08) !important;
  }
}
</style>