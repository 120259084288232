<template>
  <div class="cl-inventory noselect flex h-max mx-20 my-3">
    <v-list lines="two">
      <v-list-item
        v-for="(item, index) in lstItem"
        :key="index"
        class="hover:bg-customPurple4"
        @click="onHandleActiveItem(item)"
      >
        <template v-slot:title>
          <div class="w-max flex cl-font-16-bold uppercase">
            <div class="w-2 mr-3" style="margin-top: -2px">
              <v-icon
                v-show="itemActive?.id === item.id"
                color="#ff507a"
                size="22"
              >
                mdi-chevron-right
              </v-icon>
            </div>
            <div
              :class="
                itemActive?.id === item.id ? 'text-white' : 'text-customPurple'
              "
            >
              {{ item.title }}
            </div>
          </div>
        </template>
      </v-list-item>
    </v-list>
    <div class="flex-1 cl-components">
      <Transition mode="out-in" name="slide-fade">
        <component
          :is="itemActive?.component"
          :lstSubCategory="itemActive?.lstSubCategory"
        />
      </Transition>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import constants from "@/constants";

// components
import Items from "./components/Items.vue";

// services
import { ProfileService } from "@/modules/profile/services/profileService";

// sotorage
import { getUserInfo } from "@/helpers/storage";

export default {
  name: "InventoryVue",

  components: {
    Items,
  },

  setup() {
    const itemActive = ref(null);
    const lstItem = ref([
      {
        id: 1,
        title: "Cá nhân",
        lstSubCategory: [
          {
            title: "Avatar",
            lstItem: [],
            subCategory: [constants.SUB_CATEGORY.avatarGB],
          },
          {
            title: "Khung VIP",
            lstItem: [],
            subCategory: [constants.SUB_CATEGORY.frameGB],
          },
        ],
        getData: () => geItems(),
        component: Items,
      },
      {
        id: 2,
        title: "Vật phẩm",
        lstSubCategory: [
          {
            title: "Bổ trợ",
            showTitleItem: true,
            lstItem: [],
            subCategory: [
              constants.SUB_CATEGORY.nameChangeCard,
              constants.SUB_CATEGORY.resetRankingCS2,
              constants.SUB_CATEGORY.boostCS2,
              constants.SUB_CATEGORY.CS2RankingTicket,
            ],
          },
        ],
        getData: () => geItems(),
        component: Items,
      },
    ]);

    function onHandleActiveItem(item) {
      itemActive.value = item;
      itemActive.value.getData();
    }

    function geItems() {
      try {
        itemActive.value.lstSubCategory.forEach(async (el) => {
          el.lstItem = await getItemsOwned(el.subCategory);
        });
      } catch (error) {
        console.log(error);
      }
    }

    async function getItemsOwned(subCategory) {
      var lstItem = [];
      for (let index = 0; index < subCategory.length; index++) {
        const res = await ProfileService.getPersonalItems(subCategory[index]);
        lstItem = lstItem.concat(res.Data);
      }
      return lstItem;
    }

    return {
      lstItem,
      itemActive,
      onHandleActiveItem,
    };
  },

  watch: {
    "$route.query.subTabItem"() {
      const item = this.$route.query?.subTabItem || 0;
      this.onHandleActiveItem(this.lstItem[item]);
    },
  },

  mounted() {
    const item = this.$route.query?.subTabItem || 0;
    this.onHandleActiveItem(this.lstItem[item]);
    this.onListenMessageMqtt();
  },

  methods: {
    onListenMessageMqtt() {
      const userInfo = JSON.parse(getUserInfo());
      const topicOpenId = `${process.env.VUE_APP_TOPIC_OPENID}${userInfo.user_id}`;

      this.$clientMqtt.on("message", async (topic, message) => {
        if (topic !== topicOpenId) {
          return;
        }
        const decoder = new TextDecoder("utf-8");
        const data = JSON.parse(decoder.decode(message));
        if (data.Action == "UpdateUserBox") {
          const itemSubCategory = this.itemActive.lstSubCategory.find((it) =>
            it.subCategory.includes(data.ItemSubcategoryId)
          );
          if (itemSubCategory) {
            const itemIndex = itemSubCategory.lstItem.findIndex(
              (it) => it.ItemId === data.ItemId
            );
            if (itemIndex > -1) {
              if (data.Quantity <= 0) {
                itemSubCategory.lstItem.splice(itemIndex, 1);
                return;
              }
              if (data.Quantity > 0) {
                itemSubCategory.lstItem[itemIndex].Quantity = data.Quantity;
              }
            }
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.cl-inventory {
  height: calc(100vh - 350px);
  overflow: hidden;
  .cl-components {
    min-width: 990px;
    padding-right: 5px;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
  }
  .v-list {
    width: 200px;
    min-width: max-content;
    height: max-content;
    color: #fff;
    margin-right: 20px;
    border-radius: 10px;
    border: none !important;
    margin-top: 0px !important;
    background: rgba(123, 114, 198, 0.08) !important;
  }
}
</style>
