<template>
  <v-dialog v-model="dialogOption" max-width="450" persistent>
    <div class="cl-dialog">
      <img
        class="cl-bg"
        src="@/assets/images/dialog/bg_count_down.svg"
        alt=""
      />
      <v-btn
        class="cl-btn-close"
        size="24"
        variant="plain"
        color="#fff"
        @click.stop="onCloseDialog"
      >
        <v-icon size="24">mdi-close</v-icon>
      </v-btn>
      <div class="cl-title mt-5">Tuỳ chọn</div>
      <div class="cl-option">
        <span class="text-white cl-font-14-bold">Tên đội</span>
        <v-text-field
          v-model="dataOption.party_name"
          flat
          dirty
          hide-details
          density="compact"
          variant="solo"
          maxlength="25"
          bg-color="rgba(255, 255, 255, 0.1)"
          color="rgba(255, 255, 255, 1)"
        >
          <template v-slot:prepend-inner>
            <v-icon color="rgba(255, 255, 255, 1)">
              mdi-account-group-outline
            </v-icon>
          </template>
        </v-text-field>
      </div>
      <div class="cl-option flex justify-between mt-0 h-5">
        <div class="flex flex-1">
          <img
            class="mr-2 w-3.5"
            src="@/assets/images/icons/vip_badge.svg"
            alt=""
            srcset=""
          />
          <span class="text-white cl-font-14-bold">VIP mới được vào</span>
        </div>
        <div>
          <label class="switch">
            <input v-model="dataOption.vip_require" type="checkbox" />
            <span class="slider round"></span>
          </label>
        </div>
      </div>
      <div class="cl-option flex justify-between mt-0 h-5">
        <div class="flex flex-1">
          <span class="text-white cl-font-14-bold">Công khai Đội</span>
        </div>
        <div>
          <label class="switch">
            <input v-model="dataOption.public_party" type="checkbox" />
            <span class="slider round"></span>
          </label>
        </div>
      </div>
      <div class="cl-option mt-0">
        <span class="text-white cl-font-14-bold">Level</span>
        <div class="flex justify-between">
          <div class="cl-select-level">
            <span class="text-customPurple cl-font-12-normal mr-3"> Từ </span>
            <v-select
              v-model="dataOption.level_min_rank_require"
              :items="lstLevel"
              hide-details
              hide-no-data
              menu-icon=""
              variant="plain"
              density="compact"
            >
              <template v-slot:append-inner>
                <v-icon class="mt-n2 mr-1" color="#7B72C6">
                  mdi-chevron-down
                </v-icon>
              </template>
              <template v-slot:item="{ props, item }">
                <div>
                  <v-list-item v-bind="props">
                    <template v-slot:title>
                      <div class="cl-font-12-normal text-white">
                        {{ item.raw.title }}
                      </div>
                    </template>
                  </v-list-item>
                  <v-divider></v-divider>
                </div>
              </template>
              <template v-slot:selection="{ item }">
                <div class="mt-n4 text-white maxline1">
                  <span class="cl-font-12-normal">
                    {{ item.props.title }}
                  </span>
                </div>
              </template>
            </v-select>
          </div>
          <div class="cl-select-level">
            <span class="text-customPurple cl-font-12-normal mr-2"> Đến </span>
            <v-select
              v-model="dataOption.level_max_rank_require"
              :items="lstLevel"
              hide-details
              hide-no-data
              menu-icon=""
              variant="plain"
              density="compact"
            >
              <template v-slot:append-inner>
                <v-icon class="mt-n2 mr-1" color="#7B72C6">
                  mdi-chevron-down
                </v-icon>
              </template>
              <template v-slot:item="{ props, item }">
                <div>
                  <v-list-item v-bind="props">
                    <template v-slot:title>
                      <div class="cl-font-12-normal text-white">
                        {{ item.raw.title }}
                      </div>
                    </template>
                  </v-list-item>
                  <v-divider></v-divider>
                </div>
              </template>
              <template v-slot:selection="{ item }">
                <div class="mt-n4 text-white maxline1">
                  <span class="cl-font-12-normal">
                    {{ item.props.title }}
                  </span>
                </div>
              </template>
            </v-select>
          </div>
        </div>
      </div>
      <div class="cl-option mt-0">
        <span class="text-white cl-font-14-bold">ID Đội</span>
        <div class="cl-id-team">
          <div class="cl-font-12-normal text-white flex-1 maxline1">
            {{ isShowHideId ? dataOption.party_code : "*************" }}
          </div>
          <div class="flex">
            <v-btn
              width="24"
              height="24"
              icon
              variant="plain"
              color="rgba(123, 114, 198, 1)"
              @click.stop="isShowHideId = !isShowHideId"
            >
              <v-icon>{{
                isShowHideId ? "mdi-eye-off-outline" : "mdi-eye-outline"
              }}</v-icon>
            </v-btn>
            <v-btn
              width="24"
              height="24"
              icon
              variant="plain"
              color="rgba(123, 114, 198, 1)"
              @click.stop="onCopyId"
            >
              <v-icon size="18">mdi-file-multiple-outline</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
      <v-btn :loading="loading" class="cl-btn-accept" @click="onAccept">
        <span class="cl-font-18-bold">xác nhận</span>
      </v-btn>
    </div>
  </v-dialog>
</template>

<script>
import { ref, watch } from "vue";

export default {
  name: "dialogOption",
  emit: ["closeDialog", "acceptDialog"],
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      required: false,
      default: () => ({})
    },
  },
  setup(props, { emit }) {
    // store get show dialog
    const lstLevel = ref([
      {
        title: "Fighting Rooster",
        value: 1,
      },
      {
        title: "Rabbit",
        value: 2,
      },
      {
        title: "Hare",
        value: 3,
      },
      {
        title: "Snake",
        value: 4,
      },
      {
        title: "Snake Master",
        value: 5,
      },
      {
        title: "Fox",
        value: 6,
      },
      {
        title: "Fox Master",
        value: 7,
      },
      {
        title: "Eagle",
        value: 8,
      },
      {
        title: "Eagle Master",
        value: 9,
      },
      {
        title: "Supreme Dragon",
        value: 10,
      },
    ]);
    const isShowHideId = ref(false);
    const dialogOption = ref(props.showDialog);
    const dataOption = ref(props.data);
    watch(
      () => props.showDialog,
      () => {
        dialogOption.value = props.showDialog;
      }
    );
    watch(
      () => props.data,
      () => {
        dataOption.value = props.data;
      }
    );
    const onCloseDialog = () => {
      emit("closeDialog");
    };
    const onAccept = () => {
      emit("acceptDialog", dataOption.value);
    };
    const onCopyId = async () => {
      try {
        if (navigator.clipboard && navigator.clipboard.writeText) {
          await navigator.clipboard.writeText(dataOption.value.party_code);
        }
      } catch (error) {
        console.log(error);
      }
    };

    return {
      lstLevel,
      dataOption,
      dialogOption,
      isShowHideId,
      onAccept,
      onCopyId,
      onCloseDialog,
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.v-text-field input) {
  color: rgba(255, 255, 255, 1) !important;
}
.cl-dialog {
  width: 450px;
  justify-content: flex-start;
  .cl-btn-close {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .cl-btn-accept {
    width: 180px;
    height: 40px;
  }
  .cl-option {
    width: 300px;
    margin: 40px 0px 18px 0px;
    .cl-id-team {
      width: 180px;
      height: 25px;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 20px;
      background: rgba(255, 255, 255, 0.1);
    }
  }
  .cl-select-level {
    width: 128px;
    height: 25px;
    padding-left: 10px;
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.1);
    ::v-deep(.v-field__field) {
      height: 25px;
    }
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 41px;
    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 18px;
    width: 40px;
    border: 1px solid rgba(97, 59, 187, 1);
    background-color: transparent;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    &:before {
      position: absolute;
      content: "";
      height: 14px;
      width: 14px;
      left: 2px;
      top: 1px;
      background: rgba(97, 59, 187, 1);
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }
  input:checked + .slider:before {
    background: rgba(230, 186, 32, 1);
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }
  /* Rounded sliders */
  .slider.round {
    border-radius: 10px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}
</style>