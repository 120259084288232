import routesName from "./routesName";
import routesPath from "./routesPath";

// component
import EventDetail from "@/modules/missions/view/eventDetail.vue";
import RewardDetail from "@/modules/missions/view/rewardDetail.vue";

export const routersMission = [
  {
    name: routesName.EVENT_DETAIL,
    path: routesPath.EVENT_DETAIL_PATH,
    component: EventDetail,
  },
  {
    name: routesName.REWARD_HISTORY,
    path: routesPath.REWARD_HISTORY_PATH,
    component: RewardDetail,
  },
];
